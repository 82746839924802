import "@aws-amplify/ui-react/styles.css";
import { Box, Checkbox, Link, TextField } from "@mui/material";
import { ChangeEvent, useMemo, useState } from "react";
import { styled } from "styled-components";
import { column, row } from "../../lib/style/styled";
import Button from "../Common/Button";
import { useMutation } from "react-query";
import {
  signIn,
  signUp,
  confirmSignUp,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import Alert from "@mui/material/Alert";

type Route =
  | "signIn"
  | "signUp"
  | "confirmSignUp"
  | "forgotPassword"
  | "resetPassword";
interface Form {
  email: string;
  password: string;
  passwordConfirmation: string;
  code: string;
}

interface Props {
  defaultRoute?: Route;
  onSignUpSuccess?: () => void;
  onSignUpError?: () => void;
  onSignInSuccess?: () => void;
  onSignInError?: () => void;
  onConfirmSignUpSuccess?: () => void;
  onConfirmSignUpError?: () => void;
  onResendSignUpCodeSuccess?: () => void;
  onResendSignUpCodeError?: () => void;
  onSendResetPasswordCodeSuccess?: () => void;
  onSendResetPasswordCodeError?: () => void;
  onConfirmResetPasswordSuccess?: () => void;
  onConfirmResetPasswordError?: () => void;
  onClickTerms?: () => void;
}
export default function Authenticator({
  onClickTerms,
  defaultRoute,
  onSignUpSuccess,
  onSignUpError,
  onSignInSuccess,
  onSignInError,
  onConfirmSignUpSuccess,
  onConfirmSignUpError,
  onResendSignUpCodeSuccess,
  onResendSignUpCodeError,
  onSendResetPasswordCodeSuccess,
  onSendResetPasswordCodeError,
  onConfirmResetPasswordSuccess,
  onConfirmResetPasswordError,
}: Props) {
  const [route, setRoute] = useState<Route>(defaultRoute || "signIn");
  const [agreement, setAgreement] = useState<boolean>(false);
  const blankForm: Form = {
    email: "",
    password: "",
    passwordConfirmation: "",
    code: "",
  };
  const [form, setForm] = useState<Form>(blankForm);

  const isValidEmail = useMemo(() => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(form.email);
  }, [form.email]);

  const isValidPassword = useMemo(() => {
    // 正規表現で8文字以上の英数字かどうかを判定
    return /^(?=.*?[a-z])[a-z\d]{8,30}$/i.test(form.password);
  }, [form.password]);

  const isValidPasswordConfirmation = useMemo(() => {
    return form.password === form.passwordConfirmation;
  }, [form.password, form.passwordConfirmation]);

  const isValidCode = useMemo(() => {
    return form.code.length === 6;
  }, [form.code]);

  const signUpMutation = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const res = await signUp({
        username: email,
        password: password,
      });
      console.log("signUpMutation res", res);
      return res;
    },
    {
      onSuccess: () => {
        onSignUpSuccess?.();
      },
      onError: () => {
        onSignUpError?.();
      },
    },
  );

  const confirmSignUpMutation = useMutation(
    async ({
      email,
      code,
      password,
    }: {
      email: string;
      code: string;
      password: string;
    }) => {
      await confirmSignUp({
        username: email,
        confirmationCode: code,
      }),
        await signIn({
          username: email,
          password: password,
          options: {
            authFlowType: "USER_PASSWORD_AUTH",
          },
        });
    },
    {
      onSuccess: () => {
        onConfirmSignUpSuccess?.();
      },
      onError: () => {
        onConfirmSignUpError?.();
      },
    },
  );

  const signInMutation = useMutation(
    async ({ email, password }: { email: string; password: string }) =>
      await signIn({
        username: email,
        password: password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH",
        },
      }),
    {
      onSuccess: (data) => {
        if (data.isSignedIn) {
          onSignInSuccess?.();
        } else if (data.nextStep.signInStep === "CONFIRM_SIGN_UP") {
          setRoute("confirmSignUp");
        }
      },
      onError: () => {
        onSignInError?.();
      },
    },
  );

  const sendResetPasswordCodeMutation = useMutation(
    async ({ email }: { email: string }) =>
      await resetPassword({
        username: email,
      }),
    {
      onSuccess: () => {
        onSendResetPasswordCodeSuccess?.();
      },
      onError: () => {
        onSendResetPasswordCodeError?.();
      },
    },
  );

  const confirmResetPasswordMutation = useMutation(
    async ({
      email,
      code,
      newPassword,
    }: {
      email: string;
      code: string;
      newPassword: string;
    }) =>
      await confirmResetPassword({
        username: email,
        newPassword: newPassword,
        confirmationCode: code,
      }),
    {
      onSuccess: () => {
        onConfirmResetPasswordSuccess?.();
      },
      onError: () => {
        onConfirmResetPasswordError?.();
      },
    },
  );

  const resendSignUpCodeMutation = useMutation(
    async ({ email }: { email: string }) =>
      await resendSignUpCode({
        username: email,
      }),
    {
      onSuccess: () => {
        onResendSignUpCodeSuccess?.();
      },
      onError: () => {
        onResendSignUpCodeError?.();
      },
    },
  );

  const maskedEmail = (email: string) => {
    const [name, domain] = email.split("@");
    return `${name.slice(0, 1)}***@${domain.slice(0, 1)}***`;
  };

  return (
    <Container>
      {(route === "signIn" || route === "signUp") && (
        <TabContainer>
          <TabButton
            title="ログイン"
            selected={route === "signIn"}
            variant="text"
            onClick={() => setRoute("signIn")}
            size="large"
          />
          <TabButton
            title="アカウント作成"
            selected={route === "signUp"}
            variant="text"
            onClick={() => setRoute("signUp")}
            size="large"
          />
        </TabContainer>
      )}
      <ContentContainer>
        {route === "signIn" && (
          <FormContainer>
            <Alert severity="info">
              {
                "2024年6月からメールアドレス認証に変更しました。ユーザーIDでログインされていた方はご登録のメールアドレスとパスワードでログインしてください。"
              }
            </Alert>
            <InputContainer>
              <InputLabel>メールアドレス</InputLabel>
              <InputField
                size="small"
                placeholder="メールアドレスを入力"
                value={form.email}
                color={isValidEmail ? "success" : "primary"}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    email: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>パスワード</InputLabel>
              <InputField
                size="small"
                placeholder="パスワードを入力"
                type="password"
                color={isValidPassword ? "success" : "primary"}
                value={form.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    password: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <SubmitButton
              title="ログイン"
              loading={signInMutation.isLoading}
              disabled={!isValidEmail || !isValidPassword}
              variant="contained"
              onClick={async () => {
                console.log("signInMutation");
                await signInMutation.mutateAsync({
                  email: form.email,
                  password: form.password,
                });
              }}
            />
            {signInMutation.isError && (
              <ErrorText>
                {(signInMutation.error as Error).name ===
                "NotAuthorizedException"
                  ? "メールアドレスまたはパスワードが間違っています"
                  : (signInMutation.error as Error).name ===
                    "UserNotFoundException"
                  ? "ユーザーが見つかりませんでした"
                  : "エラーが発生しました"}
              </ErrorText>
            )}
            <SubButton
              title="パスワードを忘れた方はこちら"
              variant="text"
              size="small"
              onClick={() => setRoute("forgotPassword")}
            />
          </FormContainer>
        )}
        {route === "signUp" && (
          <FormContainer>
            <InputContainer>
              <InputLabel>メールアドレス</InputLabel>
              <InputField
                size="small"
                placeholder="メールアドレスを入力"
                color={isValidEmail ? "success" : "primary"}
                value={form.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    email: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>パスワード</InputLabel>
              <InputField
                size="small"
                placeholder="パスワードを入力"
                color={isValidPassword ? "success" : "primary"}
                type="password"
                value={form.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    password: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>パスワードの確認</InputLabel>
              <InputField
                size="small"
                placeholder="パスワードを入力"
                color={isValidPasswordConfirmation ? "success" : "primary"}
                type="password"
                value={form.passwordConfirmation}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    passwordConfirmation: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <AgreementContainer>
              <Checkbox
                name="acknowledgement"
                checked={agreement}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setAgreement(e.target.checked);
                }}
              />
              <TermsTextContainer>
                <TermsLink onClick={onClickTerms}>利用規約</TermsLink>
                <TermsText sx={{ marginLeft: 0 }}>に同意する</TermsText>
              </TermsTextContainer>
            </AgreementContainer>
            <SubmitButton
              title="アカウント作成"
              loading={signUpMutation.isLoading}
              disabled={
                !isValidEmail ||
                !isValidPassword ||
                !isValidPasswordConfirmation ||
                !agreement
              }
              variant="contained"
              onClick={async () => {
                console.log("signUpMutation");
                await signUpMutation.mutateAsync({
                  email: form.email,
                  password: form.password,
                });
                setForm({
                  ...form,
                  code: "",
                });
                setRoute("confirmSignUp");
              }}
            />
            {signUpMutation.isError && (
              <ErrorText>
                {(signUpMutation.error as Error).name ===
                "UsernameExistsException"
                  ? "ユーザーは既に登録されています"
                  : "ユーザー登録に失敗しました。管理者にお問い合わせください。"}
              </ErrorText>
            )}
          </FormContainer>
        )}
        {route === "confirmSignUp" && (
          <FormContainer>
            <FormTitle>コードを送信しました</FormTitle>
            <FormDescription>
              {`ログインするには、メール${maskedEmail(
                form.email,
              )}に記載されたコードを入力してください。到着するまでに
              1 分かかることがあります。`}
            </FormDescription>
            <InputContainer>
              <InputLabel>確認コード</InputLabel>
              <InputField
                size="small"
                placeholder="コードを入力"
                value={form.code}
                color={isValidCode ? "success" : "primary"}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    code: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <SubmitButton
              title="確定"
              loading={confirmSignUpMutation.isLoading}
              variant="contained"
              disabled={!isValidCode}
              onClick={async () => {
                await confirmSignUpMutation.mutateAsync({
                  email: form.email,
                  code: form.code,
                  password: form.password,
                });
                setRoute("signIn");
              }}
            />
            {confirmSignUpMutation.isError && (
              <ErrorText>
                {(confirmSignUpMutation.error as Error).name ===
                "ConfirmSignUpException"
                  ? "入力内容に誤りがあります"
                  : (confirmSignUpMutation.error as Error).name ===
                    "AuthValidationErrorCode"
                  ? "入力内容に誤りがあります"
                  : "エラーが発生しました"}
              </ErrorText>
            )}
            <SubmitButton
              title="コードを再送信"
              loading={resendSignUpCodeMutation.isLoading}
              variant="outlined"
              onClick={async () => {
                await resendSignUpCodeMutation.mutateAsync({
                  email: form.email,
                });
              }}
            />
          </FormContainer>
        )}
        {route === "forgotPassword" && (
          <FormContainer>
            <FormTitle>パスワードをリセット</FormTitle>
            <InputContainer>
              <InputLabel>メールアドレス</InputLabel>
              <InputField
                size="small"
                placeholder="メールアドレスを入力"
                color={isValidEmail ? "success" : "primary"}
                value={form.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    email: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <SubmitButton
              title="コードを送信"
              loading={sendResetPasswordCodeMutation.isLoading}
              disabled={!isValidEmail}
              variant="contained"
              onClick={async () => {
                await sendResetPasswordCodeMutation.mutateAsync({
                  email: form.email,
                });
                setForm({
                  ...form,
                  code: "",
                  password: "",
                  passwordConfirmation: "",
                });
                setRoute("resetPassword");
              }}
            />
            {sendResetPasswordCodeMutation.isError && (
              <ErrorText>
                {(confirmResetPasswordMutation.error as Error).name ===
                "AuthValidationErrorCode"
                  ? "入力内容に誤りがあります"
                  : (confirmResetPasswordMutation.error as Error).name ===
                    "ForgotPasswordException"
                  ? "入力内容に誤りがあります"
                  : "エラーが発生しました"}
              </ErrorText>
            )}
            <SubButton
              title="ログインに戻る"
              variant="text"
              onClick={() => setRoute("signIn")}
            />
          </FormContainer>
        )}
        {route === "resetPassword" && (
          <FormContainer>
            <FormTitle>パスワードをリセット</FormTitle>
            <InputContainer>
              <InputLabel>コード</InputLabel>
              <InputField
                size="small"
                placeholder="コードを入力"
                value={form.code}
                color={isValidCode ? "success" : "primary"}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    code: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>新しいパスワード</InputLabel>
              <InputField
                size="small"
                placeholder="新しいパスワードを入力"
                color={isValidPassword ? "success" : "primary"}
                type="password"
                value={form.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    password: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>パスワードの確認</InputLabel>
              <InputField
                size="small"
                placeholder="パスワードを入力"
                color={isValidPasswordConfirmation ? "success" : "primary"}
                type="password"
                value={form.passwordConfirmation}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setForm({
                    ...form,
                    passwordConfirmation: e.target.value,
                  });
                }}
              />
            </InputContainer>
            <SubmitButton
              title="送信"
              loading={confirmResetPasswordMutation.isLoading}
              variant="contained"
              disabled={
                !isValidCode || !isValidPassword || !isValidPasswordConfirmation
              }
              onClick={async () => {
                await confirmResetPasswordMutation.mutateAsync({
                  email: form.email,
                  code: form.code,
                  newPassword: form.password,
                });
                setRoute("signIn");
              }}
            />
            {confirmResetPasswordMutation.isError && (
              <ErrorText>
                {(confirmResetPasswordMutation.error as Error).name ===
                "AuthValidationErrorCode"
                  ? "入力内容に誤りがあります"
                  : (confirmResetPasswordMutation.error as Error).name ===
                    "ConfirmForgotPasswordException"
                  ? "入力内容に誤りがあります"
                  : "エラーが発生しました"}
              </ErrorText>
            )}
            <SubButton
              title="コードを再送信"
              variant="text"
              onClick={() => {
                sendResetPasswordCodeMutation.mutate({ email: form.email });
              }}
            />
          </FormContainer>
        )}
      </ContentContainer>
    </Container>
  );
}

const Container = styled(Box)`
  ${column};
  width: 100%;
  background-color: white;
  max-width: 500px;
`;

const ContentContainer = styled(Box)`
  ${column};
  gap: 16px;
  padding: 32px;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  font-weight: bold;
`;

const TabContainer = styled(Box)`
  ${row};
`;

const FormTitle = styled.text`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`;

const FormDescription = styled.text`
  font-size: 16px;
`;

const TabButton = styled(Button)<{ selected?: boolean }>`
  border-radius: 0px;
  border-color: ${(props) => (props.selected ? "#2196f3" : "white")};
  border-width: 0px;
  border-top-width: 3px;
  border-style: solid;
`;

const FormContainer = styled(Box)`
  ${column};
  gap: 16px;
`;

const InputContainer = styled(Box)`
  ${column};
  gap: 8px;
`;

const InputLabel = styled.text`
  font-size: 16px;
`;

const InputField = styled(TextField)`
  width: 100%;
`;

const SubButton = styled(Button)`
  font-size: 14px;
  font-weight: normal;
`;

const AgreementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TermsLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

const TermsText = styled.text`
  font-size: 14px;
`;

const ErrorText = styled.text`
  font-size: 14px;
  color: red;
  text-align: center;
`;
