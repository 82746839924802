import { Box, Button, Drawer, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";

export interface DocumentSideBarCategory {
  title: string;
  items: DocumentSideBarCategoryItem[];
}

export interface DocumentSideBarCategoryItem {
  path: string;
  name: string;
}

interface Props {
  docCategories: DocumentSideBarCategory[];
  onClick?: (item: DocumentSideBarCategoryItem) => void;
  drawer?: boolean;
  open?: boolean;
  onClose?: () => void;
}

const DocumentSideBar: FC<Props> = ({
  docCategories,
  onClick,
  drawer,
  open,
  onClose,
}) => {
  const body = (
    <Container>
      {docCategories.map((category, index) => (
        <Box key={index}>
          <SideBarTitle>{category.title}</SideBarTitle>
          {category.items.map((item, index) => (
            <DocumentSideBarItem
              key={index}
              item={item}
              onClick={() => {
                onClick?.(item);
              }}
            />
          ))}
        </Box>
      ))}
    </Container>
  );
  return (
    <Box>
      {drawer ? (
        <Drawer anchor={"left"} open={open} onClose={onClose}>
          {body}
        </Drawer>
      ) : (
        body
      )}
    </Box>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 4,
      sm: 4,
      md: 2,
    },
  }),
);

const SideBarTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 2,
    paddingBottom: 2,
  }),
);

const DocumentSideBarItem = ({
  item,
  onClick,
}: {
  item: DocumentSideBarCategoryItem;
  onClick?: () => void;
}) => (
  <SideBarItemButton size="small" onClick={onClick}>
    <SideBarItemText>{item.name}</SideBarItemText>
  </SideBarItemButton>
);

const SideBarItemButton = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    maxWidth: 250,
    minWidth: 200,
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: 2,
    cursor: "pointer",
    textTransform: "none",
    justifyContent: "flex-start",
  }),
);

const SideBarItemText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "black",
    fontSize: 14,
    textAlign: "left",
  }),
);

export default DocumentSideBar;
