import React from "react";
import { Typography } from "@mui/material";

export interface Props {
  name: string;
}

const SideBarBrand: React.FC<Props> = ({ name }) => {
  return (
    <div
      style={{
        backgroundColor: "#277148",
        height: 65,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
        {name}
      </Typography>
    </div>
  );
};

export default SideBarBrand;
