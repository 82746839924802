import { Download, FlightTakeoff } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { Box } from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserAuth from "../components/Auth/UserAuth";
import Header from "../components/UserHome/Header";
import SideBar, {
  SideBarItem,
  SideBarSection,
} from "../components/UserHome/SideBar";

export default function UserLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const sidebarSections: SideBarSection[] = [
    {
      title: "クライアントエリア",
      items: [
        //{ icon: Speed, name: "Dashboard", href: "/dashboard" },
        //{ icon: History, name: t("sidebar.backtests"), href: `/${user.username}/backtests` },
        {
          icon: Download,
          name: "ダウンロード",
          href: `/console/download`,
        },
        { icon: FlightTakeoff, name: "プラン", href: `/console/plan` },
        {
          icon: ImportContactsIcon,
          name: "ドキュメント",
          href: `/docs/started`,
          newTab: true,
        },
      ],
    },
  ];

  const title = "Backtests";
  const navigate = useNavigate();
  const [selected, setSelected] = useState<SideBarItem | null>(null);

  const handleClickItem = async (item: SideBarItem) => {
    if (item.callback) {
      await item.callback();
    }
    if (item.newTab) {
      window.open(item.href);
    } else {
      navigate(item.href);
    }
    if (item.reload) {
      window.location.reload();
    }
    setSelected(item);
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/");
    window.location.reload();
  };

  const handleMoveAccountSettings = () => {
    navigate(`/console/account-settings`);
  };

  return (
    <UserAuth>
      <div style={{ backgroundColor: "#e8ecef", minHeight: "100vh" }}>
        <Box
          sx={{
            marginLeft: { sm: "none", md: "25vw", lg: "20vw" },
            width: { sm: "100vw", md: "75vw", lg: "80vw" },
          }}
        >
          <Header
            title={title}
            onClickSignOut={handleSignOut}
            onClickDrawerToggle={handleDrawerToggle}
            onClickAccountSettings={handleMoveAccountSettings}
          />
        </Box>
        <Box sx={{}}>
          <SideBar
            selectedItem={selected}
            sections={sidebarSections}
            onClickItem={handleClickItem}
            mobileOpen={mobileOpen}
            onCloseMobileOpen={handleDrawerToggle}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { sm: "none", md: "25vw", lg: "20vw" },
            width: { sm: "100vw", md: "75vw", lg: "80vw" },
          }}
        >
          <Outlet />
        </Box>
      </div>
    </UserAuth>
  );
}
