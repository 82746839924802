import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import { SideBarItem, SideBarSection } from "./SideBar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
});

export interface Props {
  selectedItem: SideBarItem | null;
  sections: SideBarSection[];
  onClickItem: (item: SideBarItem) => void;
}

const SideBarNav: React.FC<Props> = ({
  selectedItem,
  sections,
  onClickItem,
}) => {
  const classes = useStyles();
  return (
    <List>
      {sections.map((section: SideBarSection, i: number) => (
        <div key={i}>
          {section.title && (
            <div style={{ marginTop: 10, marginLeft: 10 }}>
              <Button
                disabled
                style={{ fontWeight: "bold", fontSize: 13, color: "#e8ecef" }}
              >
                {section.title}
              </Button>
            </div>
          )}
          {section.items.map((item: SideBarItem, i: number) => (
            <div key={i}>
              {item.accordion && (
                <div>
                  <Accordion
                    elevation={0}
                    style={{ backgroundColor: "#2e8555" }}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore style={{ color: "white" }} />}
                      id="panel1a-header"
                    >
                      <ListItemIcon style={{ color: "#e5e4e6" }}>
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontSize: 16,
                          color: "#e5e4e6",
                          fontWeight:
                            selectedItem?.name === item.name
                              ? "bold"
                              : undefined,
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {item.items?.map((item: any, i: number) => (
                          <ListItem
                            dense
                            button
                            key={i}
                            onClick={() => {
                              onClickItem(item);
                            }}
                            style={{
                              backgroundColor:
                                selectedItem?.name === item.name
                                  ? "#95949a"
                                  : undefined,
                            }}
                          >
                            <ListItemIcon style={{ color: "#e5e4e6" }}>
                              <item.icon />
                            </ListItemIcon>
                            <ListItemText
                              primary={item.name}
                              primaryTypographyProps={{
                                fontSize: 16,
                                color: "#e5e4e6",
                                fontWeight:
                                  selectedItem?.name === item.name
                                    ? "bold"
                                    : undefined,
                              }}
                            />
                          </ListItem>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {!item.accordion && (
                <ListItem
                  button
                  key={i}
                  onClick={() => {
                    onClickItem(item);
                  }}
                  style={{
                    backgroundColor:
                      selectedItem?.name === item.name ? "#95949a" : undefined,
                  }}
                >
                  <ListItemIcon style={{ color: "#e5e4e6" }}>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      fontSize: 16,
                      color: "#e5e4e6",
                      fontWeight:
                        selectedItem?.name === item.name ? "bold" : undefined,
                    }}
                  />
                  {item.newTab && (
                    <ListItemIcon
                      style={{ color: "#e5e4e6", justifyContent: "flex-end" }}
                    >
                      <OpenInNewIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              )}
            </div>
          ))}
        </div>
      ))}
    </List>
  );
};

export default SideBarNav;
