import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Authenticator from "../components/Auth/Authenticator";
import { SetUIVocabularies } from "../lib/UIVocabularies";
import { useSetRecoilState } from "recoil";
import { snackbarState } from "../recoil/snackbar";

export default function Login() {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const setSnackbarState = useSetRecoilState(snackbarState);

  // 日本語に設定
  SetUIVocabularies("ja");
  // ログイン画面の初期表示設定
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");

  useEffect(() => {
    // sign-in, sign-up後にユーザーページにリダイレクト
    if (user) {
      navigate(`/console`);
    }
  }, [user]);

  const moveToTerms = () => {
    window.open("/terms/terms-of-service", "_blank");
  };

  return (
    <Container>
      <AppTitle>
        Metalify<span>へようこそ！</span>
      </AppTitle>
      <Authenticator
        defaultRoute={state === "signUp" ? "signUp" : "signIn"}
        onClickTerms={moveToTerms}
        onSignUpSuccess={() => {}}
        onSignUpError={() => {
          setSnackbarState({
            open: true,
            message: "登録に失敗しました。",
            severity: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onSignInSuccess={() => {
          window.location.reload();
        }}
        onSignInError={() => {
          setSnackbarState({
            open: true,
            message: "ログインに失敗しました。",
            severity: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onConfirmSignUpSuccess={() => {
          //navigate(`/console`);
          window.location.reload();
        }}
        onConfirmSignUpError={() => {
          setSnackbarState({
            open: true,
            message: "登録に失敗しました。",
            severity: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onResendSignUpCodeSuccess={() => {
          setSnackbarState({
            open: true,
            message: "確認コードを再送しました。",
            severity: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onResendSignUpCodeError={() => {
          setSnackbarState({
            open: true,
            message: "確認コードの再送に失敗しました。",
            severity: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onSendResetPasswordCodeSuccess={() => {
          setSnackbarState({
            open: true,
            message: "パスワードリセットコードを送信しました。",
            severity: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onSendResetPasswordCodeError={() => {
          setSnackbarState({
            open: true,
            message: "パスワードリセットコードの送信に失敗しました。",
            severity: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onConfirmResetPasswordSuccess={() => {
          setSnackbarState({
            open: true,
            message: "パスワードをリセットしました。",
            severity: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
        onConfirmResetPasswordError={() => {
          setSnackbarState({
            open: true,
            message: "パスワードのリセットに失敗しました。",
            severity: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            onClose: () => setSnackbarState(null),
          });
        }}
      />
    </Container>
  );
}

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    minHeight: "90vh",
    paddingTop: {
      xs: 4,
      sm: 8,
    },
    paddingBottom: {
      xs: 4,
      sm: 8,
    },
    gap: {
      xs: 2,
      sm: 4,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: 'url("/img/sign_background.jpeg")',
    backgroundSize: "cover",
  }),
);

const AppTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: 32,
      sm: 36,
      md: 40,
      lg: 44,
    },
    fontWeight: "bold",
    color: "white",
    span: {
      fontSize: {
        xs: 24,
        sm: 28,
        md: 32,
        lg: 36,
      },
    },
  }),
);
