import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { ja } from "./ja";

const lang = navigator.language;
const resources = {
  en: en,
  ja: ja,
};

i18n.use(initReactI18next).init({
  resources,
  lng: lang.includes("ja") ? "ja" : "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
