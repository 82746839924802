import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export default function NotFound() {
  return (
    <Container>
      <Title>Metalify</Title>
      <Subtitle sx={{ marginTop: 2 }}>ページが見つかりませんでした</Subtitle>
      <Description sx={{ marginTop: 2 }}>
        ページが削除されたかURLが間違っている可能性があります
      </Description>
    </Container>
  );
}

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "70vh",
    padding: 4,
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    fontSize: 64,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const Subtitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    fontSize: 32,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);
