import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {
  onClickGetStarted?: () => void;
}

const MainHeader: React.FC<Props> = ({ onClickGetStarted }) => {
  return (
    <HeaderContainer>
      <Title sx={{ marginTop: 4 }}>{"Metalify"}</Title>
      <Description>{"MetaTrader4対応 FX練習ソフト"}</Description>
      <GetStartedButton
        sx={{
          marginTop: {
            xs: 8,
            sm: 8,
            md: 8,
          },
        }}
        onClick={onClickGetStarted}
      >
        {"いますぐ始める"}
      </GetStartedButton>
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    minHeight: "60vh",
    backgroundImage: "url(/img/home_background.png)",
    backgroundSize: "cover",
    paddingTop: {
      xs: 4,
      sm: 8,
      md: 8,
    },
    paddingBottom: {
      xs: 4,
      sm: 8,
      md: 8,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "white",
    fontSize: {
      xs: 56,
      sm: 72,
      md: 84,
    },
    fontWeight: "bold",
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "white",
    fontSize: {
      xs: 22,
      sm: 26,
      md: 30,
    },
    fontWeight: "bold",
  }),
);

const GetStartedButton = (props: ButtonProps) => (
  <Button
    {...props}
    sx={{
      fontSize: 16,
      textTransform: "none",
      fontWeight: "bold",
      backgroundColor: "#f08300",
      padding: 2,
      paddingLeft: 4,
      paddingRight: 4,
      ...props.sx,
    }}
    variant="contained"
    disableElevation
  >
    {props.children}
  </Button>
);

export default MainHeader;
