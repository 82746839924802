export const en = {
  translation: {
    header: {
      title: "Metalify",
      login: "Login",
    },
    home: {
      title: "Metalify",
      description: "Backtesting tool for discretionary traders on MetaTrader 4",
      getStarted: "Get Started",
      introVideoTitle: "Check it out in the intro video",
      mainFeatures: "Main Features",
      feature1: {
        title: "Easy to Use",
        description:
          "EAs and indicators can be easily implemented in as little as 5 minutes.",
      },
      feature2: {
        title: "Analyze Backtesting Result",
        description:
          "Backtest results can be stored in the cloud, providing many analytical results such as expected value and profit/loss ratios.",
      },
      feature3: {
        title: "Works with MetaTrader4",
        description:
          "It can be used with your MetaTrader 4, with MT4's indicators and features.",
      },
    },
    sidebar: {
      backtests: "Backtests",
      download: "Download",
      token: "API Key",
      titleSettings: "Settings",
      titleClientArea: "Client Area",
      documents: "Documents",
      docTutorial: "Tutorial",
      docHowToBacktest: "How to Backtest",
      docAnalysis: "How to View the Analysis",
      docHowToSynchronization: "How to Synchronize Time",
      backToWebsite: "Back to website",
    },
    backtests: {
      titleOngoing: "Ongoing",
      titleEnded: "Ended",
      buttonNew: "New",
      panelBalance: "Balance",
      panelStart: "Start",
      panelEnd: "End",
      panelCredentials: "Credentials",
      panelAnalysis: "Analysis",
      panelDelete: "Delete",
      panelMenuEnd: "End",
    },
    deleteBacktestDialog: {
      title: "Delete Backtest",
      message:
        "Deletion cannot be undone. Are you sure you want to delete this backtest?",
      cancel: "Cancel",
      delete: "Delete",
      finished: "Deleted!",
    },
    credentials: {
      title: "Backtest Credentials",
      titleBacktestID: "Backtest ID",
      titleWebRequestURL: "WebRequest URL",
    },
    download: {
      titleExpertAdviser: "Expert Adviser",
      titleIndicators: "Indicators",
      titleMetalifyEA: "Metalify EA",
      descMetalifyEA: "Metalify expert advisor for back testing.",
      titleMetalifyController: "Metalify Controller",
      descMetalifyController:
        "Metalify indicator required for order execution.",
      titleMetalifySynchronizer: "Metalify Synchronizer",
      descMetalifySynchronizer:
        "Indicator required for time synchronization with other charts during back testing.",
    },
    token: {
      titleAPIToken: "API Key",
      titleMetalifyAPIToken: "Metalify API Key",
      descMetalifyAPIToken:
        "API Key for connecting metalify api. *Do not show anyone.",
      descAPIKeyWarning:
        "API Key is a secret key that allows you to access your account. Do not show anyone.",
      descRegenerateAPIKey: "Regenerate API Key",
      descGenerateAPIKey: "Generate API Key",
      titleExpertProperties: "Expert Properties",
      titleMetalifyExpertProperties: "Metalify Properties File (.set)",
      descMetalifyExpertProperties:
        "Expert properties file for back testing of local mode.",
    },
    analysis: {
      currentResult: {
        title: "Current Result",
        xAxis: "Number of trades",
        yAxis: "Balance",
      },
      backtestInformation: {
        title: "Backtest Information",
        ID: "Backtest ID",
        status: "Status",
        start: "Start",
        end: "End",
        symbol: "Symbol",
        balance: "Account Balance",
        createdAt: "Created At",
      },
      growth: {
        title: "Growth",
        yAxis: "Growth",
      },
      statistics: {
        title: "Statistics",
        equity: "Equity",
        balance: "Balance",
        numberOfTrades: "Number of trades",
        lots: "Lots",
        winRate: "Win Rate",
        averageProfit: "Average Profit",
        averageLoss: "Average Loss",
        averageRRR: "Average RRR",
        averageRRRDescription:
          "RRR (Reward-Risk-Ratio) is the ratio between the average profit and average loss of your account positions. RRR alone does not necessarily signify a profitable trading system if not considered together with Win rate.",
        expectancy: "Expectancy",
        expectancyDescription:
          "Expectancy projects the hypothetical value of any future single trade, based on the ratio of your acount win ration, loss ratio and RRR.",
        profitFactor: "Profit Factor",
        profitFactorDescription:
          "Profit factor is the ratio of gross profits devided by gross losses. If the Profit factor is above 1, the trading system indicates profitability. The higher the Profit factor, the better.",
      },
      dailySummary: {
        title: "Daily Summary",
      },
      tradingJournal: {
        title: "Trading Journal",
      },
    },
  },
};
