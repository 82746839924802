import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {
  price: string;
  description: string;
  color?: string;
}

const Price: React.FC<Props> = ({ price, description, color }) => {
  return (
    <Container>
      <PriceContainer>
        <YenText sx={{ color: color }}>{"¥"}</YenText>
        <PriceText sx={{ color: color }}>{price}</PriceText>
        <TaxText sx={{ color: color }}>{"(税込)"}</TaxText>
      </PriceContainer>
      <PeriodText sx={{ color: color }}>{description}</PeriodText>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
);

const PriceContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  }),
);

const YenText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 68,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const PriceText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 88,
    fontWeight: "bold",
    color: "#393f4c",
    textAlignVertical: "bottom",
  }),
);

const TaxText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 16,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const PeriodText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 14,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

export default Price;
