import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useMatch, useNavigate } from "react-router-dom";
import DocumentContent from "../components/Docs/DocumentContent";
import DocumentSideBar, {
  DocumentSideBarCategory,
} from "../components/Docs/DocumentSideBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const docCategories: DocumentSideBarCategory[] = [
  {
    title: "導入ガイド",
    items: [
      {
        path: "started",
        name: "はじめに",
      },
      {
        path: "plan",
        name: "プランを購読する",
      },
      {
        path: "install-app",
        name: "アプリを導入する",
      },
      {
        path: "mt4-settings",
        name: "MT4の設定を行う",
      },
    ],
  },
  {
    title: "操作ガイド",
    items: [
      {
        path: "ea-parameters",
        name: "ストラテジーテスターの設定",
      },
      {
        path: "offline-chart-settings",
        name: "複数のチャートを表示する",
      },
      {
        path: "control-panel",
        name: "操作パネルの使い方",
      },
      {
        path: "player",
        name: "スピードを変更する",
      },
      {
        path: "order",
        name: "注文を行う",
      },
      {
        path: "how-to-backtest",
        name: "バックテストの進め方",
      },
      {
        path: "download-history-data",
        name: "ヒストリーデータをダウンロードする",
      },
    ],
  },
  {
    title: "トラブルシューティング",
    items: [
      {
        path: "ts-0-general-troubleshooting",
        name: "一般的なエラーとトラブルシューティング",
      },
      {
        path: "ts-1-offline-chart-not-generate",
        name: "オフラインチャートが生成されません",
      },
      {
        path: "ts-2-tester-not-start",
        name: "テスターを開始してもすぐに停止してしまいます",
      },
    ],
  },
  {
    title: "その他",
    items: [
      {
        path: "faq",
        name: "FAQ よくあるご質問",
      },
    ],
  },
];

export default function Docs() {
  const navigate = useNavigate();
  const match = useMatch("/docs/:name");

  const {
    data: document,
    isLoading,
    isError,
  } = useQuery(
    ["tasks", match],
    async () => {
      const documentPath = match?.params.name;
      try {
        const res = await fetch(
          process.env.PUBLIC_URL + `/docs/${documentPath}.md`,
        );
        const text = await res.text();
        if (text.includes("!DOCTYPE")) throw new Error("Document not found");
        return text;
      } catch (err) {
        throw new Error("Document not found");
      }
    },
    {
      retry: false,
    },
  );

  // mdより小さい場合はdrawerを表示
  const theme = useTheme();
  const lessThanMid = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  return (
    <Container>
      {lessThanMid && (
        <Box>
          <DrawerButton onClick={() => setOpen(!open)}>
            <MenuIcon />
          </DrawerButton>
        </Box>
      )}
      <DocumentSideBar
        docCategories={docCategories}
        onClick={(item) => {
          navigate(`/docs/${item.path}`);
          setOpen(false);
        }}
        drawer={lessThanMid}
        open={open}
        onClose={() => setOpen(false)}
      />
      {!lessThanMid && <Divider />}
      <DocumentContent
        document={document}
        isLoading={isLoading}
        isNotFound={isError}
      />
    </Container>
  );
}

const DrawerButton = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dcdcdc",
  }),
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
    },
    padding: {
      xs: 4,
      sm: 4,
      md: 8,
    },
    marginBottom: 8,
  }),
);

const Divider = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    borderLeftWidth: 1,
    borderStyle: "solid",
    borderColor: "#dcdcdc",
    marginRight: 4,
  }),
);
