import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const PrivacyPolicy: FC = () => {
  const [document, setDocument] = useState("");
  useEffect(() => {
    try {
      fetch(process.env.PUBLIC_URL + `/terms/privacy-policy.md`)
        .then((r) => r.text())
        .then((text) => {
          setDocument(text);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Container>
      <ReactMarkdown
        components={{
          img: ({ ...props }) => (
            <img style={{ maxWidth: "100%" }} {...props} />
          ),
        }}
      >
        {document}
      </ReactMarkdown>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    minHeight: "100vh",
    padding: {
      xs: 4,
      sm: 8,
      md: 8,
    },
    marginBottom: 8,
  }),
);

export default PrivacyPolicy;
