import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const HomeFooter: React.FC<Props> = () => {
  const appVersion = process.env.REACT_APP_VERSION;
  return (
    <Container>
      <SectionListContainer>
        <SectionContainer>
          <SectionTitle>サポート</SectionTitle>
          <SectionLink sx={{ marginTop: 2 }} href="/docs/started">
            ドキュメント
          </SectionLink>
          <SectionLink href="/pricing">ご利用料金</SectionLink>
          <SectionLink href="/support/contact">お問い合わせ</SectionLink>
          <SectionLink href="/support/faq">よくある質問</SectionLink>
        </SectionContainer>
        <SectionContainer
          sx={{
            marginTop: {
              xs: 4,
              sm: 0,
              md: 0,
            },
          }}
        >
          <SectionTitle>規約</SectionTitle>
          <SectionLink sx={{ marginTop: 2 }} href="/terms/privacy-policy">
            プライバシーポリシー
          </SectionLink>
          <SectionLink href="/terms/terms-of-service">利用規約</SectionLink>
          <SectionLink href="/terms/sct">特定商取引法に基づく表記</SectionLink>
        </SectionContainer>
      </SectionListContainer>
      <CopyRightContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <LogoTitle>Metalify</LogoTitle>
          {appVersion && <AppVersion>{`v${appVersion}`}</AppVersion>}
        </Box>
        <Copyright>Copyright ©️ 2023 Metalify</Copyright>
      </CopyRightContainer>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2b2b2b",
    padding: 8,
  }),
);

const SectionListContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
      md: "row",
    },
  }),
);

const SectionContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    paddingLeft: 4,
    paddingRight: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }),
);

const SectionTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  }),
);

const SectionLink = styled(Link)(({ theme }) =>
  theme.unstable_sx({
    color: "white",
    fontSize: 14,
    paddingTop: 1,
    paddingBottom: 1,
    cursor: "pointer",
    textDecoration: "none",
  }),
);

const CopyRightContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 12,
  }),
);

const LogoTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "white",
    fontSize: 28,
    fontWeight: "bold",
  }),
);

const AppVersion = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "white",
    fontSize: 16,
  }),
);

const Copyright = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "white",
    fontSize: 14,
  }),
);

export default HomeFooter;
