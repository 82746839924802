export const ja = {
  translation: {
    header: {
      title: "Metalify",
      login: "ログイン",
    },
    home: {
      title: "Metalify",
      description: "MetaTrader4上で行える裁量トレーダー向けバックテストツール",
      getStarted: "いますぐ始める",
      introVideoTitle: "動画で確認する",
      mainFeatures: "主な機能",
      feature1: {
        title: "最短5分で簡単導入",
        description: "EAとインジケータを用いて最短5分で簡単に導入できます。",
      },
      feature2: {
        title: "検証結果をクラウドで分析",
        description:
          "バックテストの結果をクラウド上で保存でき、期待値や損益比率など多くの分析結果を提供。",
      },
      feature3: {
        title: "MetaTrader4で動作",
        description:
          "お使いのMetaTrader4で使用できます。MT4独自のインジケーターや機能も自由に利用可能。",
      },
    },
    sidebar: {
      backtests: "バックテスト",
      download: "ダウンロード",
      titleSettings: "設定",
      titleClientArea: "クライアントエリア",
      documents: "ドキュメント",
      docTutorial: "チュートリアル",
      docHowToBacktest: "バックテストの進め方",
      docAnalysis: "バックテスト結果の分析",
      docHowToSynchronization: "時間同期の方法",
      backToWebsite: "ウェブサイトへ戻る",
    },
    backtests: {
      titleOngoing: "進行中",
      titleEnded: "終了",
      buttonNew: "作成",
      panelBalance: "初期残高",
      panelStart: "開始日",
      panelEnd: "終了日",
      panelCredentials: "認証情報",
      panelAnalysis: "分析",
      panelDelete: "削除",
      panelMenuEnd: "終了",
    },
    deleteBacktestDialog: {
      title: "バックテストの削除",
      message:
        "削除すると元に戻せません。バックテストを削除してもよろしいですか?",
      cancel: "キャンセル",
      delete: "削除",
      finished: "削除しました",
    },
    credentials: {
      title: "認証情報",
      titleBacktestID: "バックテストID",
      titleWebRequestURL: "Webリクエスト URL",
    },
    download: {
      titleExpertAdviser: "エキスパートアドバイザー",
      titleIndicators: "インジケーター",
      titleMetalifyEA: "Metalify EA",
      descMetalifyEA: "バックテスト実行に必要なエキスパートアドバイザーです",
      titleMetalifyController: "Metalify Controller",
      descMetalifyController:
        "バックテスト時の注文等の操作に必要なインジケーターです",
      titleMetalifySynchronizer: "Metalify Synchronizer",
      descMetalifySynchronizer:
        "バックテスト時の他チャートとの時間同期に必要なインジケーターです",
    },
    analysis: {
      currentResult: {
        title: "検証結果",
        xAxis: "取引数",
        yAxis: "残高",
      },
      backtestInformation: {
        title: "バックテスト情報",
        ID: "ID",
        status: "ステータス",
        start: "開始日",
        end: "終了日",
        symbol: "通貨ペア",
        balance: "初期残高",
        createdAt: "検証日",
      },
      growth: {
        title: "成長率",
        yAxis: "成長率",
      },
      statistics: {
        title: "統計",
        equity: "エクイティ",
        balance: "残高",
        numberOfTrades: "取引数",
        lots: "ロット数",
        winRate: "勝率",
        averageProfit: "平均利益",
        averageLoss: "平均損失",
        averageRRR: "平均リスクリワード比率",
        averageRRRDescription:
          "RRR (リスクリワード比率) は、ポジションの平均利益と平均損失の間の比率を表します。勝率と合わせて考えないと、RRRだけでは必ずしも収益性の高い取引システムとは言えません。",
        expectancy: "期待値",
        expectancyDescription:
          "期待値は、取引口座の勝率、損失率、RRRの比率に基づき、将来の単一取引の仮想的な価値を予測します。",
        profitFactor: "プロフィットファクター",
        profitFactorDescription:
          "プロフィットファクターとは、売上総利益を売上総損失で割った比率のことです。プロフィットファクターが1以上であれば、そのトレーディングシステムは収益性があると言えます。プロフィット・ファクターが高ければ高いほど、良いということになります。",
      },
      dailySummary: {
        title: "日次結果",
      },
      tradingJournal: {
        title: "取引一覧",
      },
    },
  },
};
