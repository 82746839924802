import React from "react";
import { Drawer } from "@mui/material";
import SideBarNav from "./SideBarNav";
import SideBarBrand from "./SideBarBrand";
import { SvgIconComponent } from "@mui/icons-material";

export interface SideBarSection {
  title: string;
  items: SideBarItem[];
}

export interface SideBarItem {
  icon: SvgIconComponent;
  name: string;
  href: string;
  newTab?: boolean;
  accordion?: boolean;
  items?: SideBarItem[];
  callback?: () => void;
  reload?: boolean;
}

export interface Props {
  selectedItem: SideBarItem | null;
  sections: SideBarSection[];
  onClickItem: (item: SideBarItem) => void;
  mobileOpen: boolean;
  onCloseMobileOpen: () => void;
}

const SideBar: React.FC<Props> = ({
  selectedItem,
  sections,
  onClickItem,
  mobileOpen,
  onCloseMobileOpen,
}) => {
  return (
    <div>
      <Drawer
        anchor={"left"}
        variant={"temporary"}
        sx={{
          display: { xs: "block", sm: "block", md: "none", lg: "none" },
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#2e8555",
            color: "white",
          },
        }}
        open={mobileOpen}
        onClose={onCloseMobileOpen}
      >
        <SideBarBrand name={"Metalify"} />
        <SideBarNav
          selectedItem={selectedItem}
          sections={sections}
          onClickItem={(item) => {
            onCloseMobileOpen();
            onClickItem(item);
          }}
        />
      </Drawer>
      <Drawer
        anchor={"left"}
        variant={"permanent"}
        sx={{
          display: { xs: "none", sm: "none", md: "block", lg: "block" },
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#2e8555",
            color: "white",
            width: { sm: 0, md: "25vw", lg: "20vw" },
          },
        }}
        open
      >
        <SideBarBrand name={"Metalify"} />
        <SideBarNav
          selectedItem={selectedItem}
          sections={sections}
          onClickItem={onClickItem}
        />
      </Drawer>
    </div>
  );
};

export default SideBar;
