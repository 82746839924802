import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import HomeFooter from "../components/Home/Footer";
import HomeHeader from "../components/Home/Header";

export default function HomeLayout() {
  const navigate = useNavigate();
  const handleLogin = async () => {
    navigate("/sign?state=signIn");
  };
  return (
    <div style={{ minHeight: "100vh" }}>
      <Box sx={{ height: { xs: "100%", md: "100%" } }}>
        <HomeHeader title={"Metalify"} onClickLogin={handleLogin} />
      </Box>
      <div>
        <Outlet />
      </div>
      <div style={{ height: "20vh" }}>
        <HomeFooter />
      </div>
    </div>
  );
}
