import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const LandingService: React.FC<Props> = () => {
  return (
    <Container>
      <Title>{"Metalifyってどんなサービス？"}</Title>
      <Image src={"/img/undraw_metalify.svg"} />
      <Subtitle>{"トレードを上達させる\n実践的なFX練習ソフト"}</Subtitle>
      <Description>
        {
          "MetaTrader4のバックテスト機能を活用した実践的なFX練習ソフトです。過去のデータを用いてFXの練習を何度でも行えます。"
        }
      </Description>
    </Container>
  );
};

const Image = styled("img")(({ theme }) =>
  theme.unstable_sx({
    width: {
      xs: "100%",
      sm: "80%",
      md: "70%",
    },
  }),
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: {
      xs: 2,
      sm: 2,
      md: 4,
      lg: 4,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
  }),
);

const Subtitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: {
      xs: "pre-wrap",
      sm: "normal",
      md: "normal",
    },
    fontSize: {
      xs: 20,
      sm: 24,
      md: 28,
    },
    fontWeight: "bold",
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: {
      xs: "pre-wrap",
      sm: "pre-wrap",
      md: "normal",
    },
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
    },
  }),
);

export default LandingService;
