import { FC, ReactNode } from "react";
import styled from "styled-components";
import { center, row } from "../../lib/style/styled";
import { CircularProgress } from "@mui/material";

export type Variant = "contained" | "outlined" | "text";
export type Color = "primary" | "secondary";
export type Size = "small" | "medium" | "large";

interface Props {
  title: string;
  variant?: Variant;
  color?: Color;
  size?: Size;
  disabled?: boolean;
  loading?: boolean;
  leftIcon?: ReactNode;
  onClick?: () => void;
}

const Button: FC<Props> = ({
  title,
  variant = "contained",
  color = "primary",
  size = "medium",
  loading = false,
  leftIcon,
  disabled,
  onClick,
  ...rest
}) => {
  return (
    <Container
      disabled={disabled || loading}
      loading={loading}
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
      {...rest}
    >
      <LeftIconContainer>{leftIcon}</LeftIconContainer>
      {!loading && (
        <TitleText variant={variant} color={color} size={size}>
          {title}
        </TitleText>
      )}
      {loading && (
        <CircularProgress
          sx={{ color: variant === "contained" ? "white" : "#2196f3" }}
          thickness={5}
          size={16}
        />
      )}
    </Container>
  );
};

const Container = styled.button<{
  variant: Variant;
  color: Color;
  size: Size;
  disabled?: boolean;
  loading?: boolean;
}>`
  background-color: ${(props) =>
    props.variant === "contained"
      ? props.disabled && !props.loading
        ? "#ccc"
        : props.color === "primary"
        ? "#2196f3"
        : props.color === "secondary"
        ? "#f44336"
        : "#ccc"
      : "transparent"};
  gap: 8px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  height: ${(props) =>
    props.size === "small"
      ? "32px"
      : props.size === "medium"
      ? `36px`
      : `40px`};
  ${row};
  border: ${(props) =>
    props.variant === "outlined"
      ? props.color === "primary"
        ? "1px solid #2196f3"
        : "2px solid #ccc"
      : "none"};
  ${center};
  &:hover {
    -webkit-filter: brightness(95%);
  }
`;

const TitleText = styled.text<{
  variant: Variant;
  color: Color;
  size: Size;
  disabled?: boolean;
}>`
  font-size: ${(props) =>
    props.size === "small" ? 18 : props.size === "medium" ? 20 : 22};
  font-weight: bold;
  color: ${(props) =>
    props.variant === "contained"
      ? "white"
      : props.color === "primary"
      ? "#2196f3"
      : "black"};
`;

const LeftIconContainer = styled.text`
  position: absolute;
  left: 16px;
  ${center};
`;

export default Button;
