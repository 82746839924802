import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";

const SCT: FC = () => {
  return (
    <Container>
      <Title>{"特定商取引法に基づく表記"}</Title>
      <TableRow title="販売事業者名" value="Metalify 平野 流" />
      <TableRow title="運営責任者名" value="平野 流" />
      <TableRow title="本社所在地" value="愛知県刈谷市野田町場割90-15" />
      <TableRow title="電話番号" value="070-8401-3530" />
      <TableRow title="メールアドレス" value="support@metalify-fx.com" />
      <TableRow title="販売価格" value="月額 980円（税込）" />
      <TableRow title="商品以外の必要代金" value="なし" />
      <TableRow
        title="お支払い方法"
        value="月額利用料はクレジットカード払いとなります"
      />
      <TableRow
        title="代金の支払い時期"
        value="初回は申し込み後トライアル期間終了時に決済、次月以降はトライアル期間終了日を基準として毎月基準日に決済"
      />
      <TableRow
        title="商品の引き渡し時期"
        value="決済完了後、すぐにご利⽤開始となります"
      />
      <TableRow
        title="返品・キャンセル"
        value="商品特性上、返金、キャンセルは受け付けておりません。月額費用の解約はいつでもマイページから行っていただけます。次回決済日までに解約をしていただければ、次回以降の請求は発生いたしません。日割りでの返金は承っておりません。"
        bottomDivider
      />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    marginBottom: 8,
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 4,
      sm: 4,
      md: 8,
    },
    textAlign: "center",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 36,
    },
    fontWeight: "bold",
  }),
);

const TableRow = (props: {
  title: string;
  value: string;
  bottomDivider?: boolean;
}) => (
  <Box
    style={{
      borderWidth: 0,
      borderColor: "#dcdcdc",
      borderBottomWidth: props.bottomDivider ? 1 : 0,
      borderStyle: "solid",
    }}
  >
    <TableRowContainer>
      <TableRowTitleContainer>
        <TableRowTitle>{props.title}</TableRowTitle>
      </TableRowTitleContainer>
      <TableRowValueContainer>
        <TableRowValue>{props.value}</TableRowValue>
      </TableRowValueContainer>
    </TableRowContainer>
  </Box>
);

const TableRowContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: "#dcdcdc",
    borderStyle: "solid",
  }),
);

const TableRowTitleContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 2,
      sm: 2,
      md: 4,
    },
    backgroundColor: "#f5f5f5",
    width: "30%",
  }),
);

const TableRowTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 16,
  }),
);

const TableRowValueContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 2,
      sm: 2,
      md: 4,
    },
    flex: 1,
  }),
);

const TableRowValue = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 16,
  }),
);

export default SCT;
