import { useAuthenticator } from "@aws-amplify/ui-react";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard: FC = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  useEffect(() => {
    // ダッシュボードは未実装のため、ダウンロードページにリダイレクト
    if (user) {
      navigate(`/console/download`);
    }
  }, [user]);

  return <div></div>;
};

export default Dashboard;
