import AppRoutes from "./Routes";
import { Authenticator } from "@aws-amplify/ui-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { SnackbarAlert } from "./recoil/snackbar";
import { ThemeProvider, createTheme } from "@mui/material";
import { Amplify } from "aws-amplify";
const config = require("./aws-exports").default;
Amplify.configure(config);

const queryClient = new QueryClient();
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Arial"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Authenticator.Provider>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <AppRoutes />
            <SnackbarAlert />
          </RecoilRoot>
        </QueryClientProvider>
      </Authenticator.Provider>
    </ThemeProvider>
  );
}

export default App;
