import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { remarkAlert } from "remark-github-blockquote-alert";
import remarkToc from "remark-toc";
import rehypeSlug from "rehype-slug";
import "./alert.css";
import "./table.css";
import { CodeBlock } from "./CodeBlock";

interface Props {
  document?: string;
  isLoading?: boolean;
  isNotFound?: boolean;
}

const DocumentContent: FC<Props> = ({ document, isLoading, isNotFound }) => (
  <Container>
    {(isLoading || isNotFound) && (
      <EmptyContainer>
        {isLoading && <Typography>Loading...</Typography>}
        {isNotFound && (
          <Typography>ドキュメントが見つかりませんでした</Typography>
        )}
      </EmptyContainer>
    )}
    <ReactMarkdown
      remarkPlugins={[
        remarkGfm,
        remarkAlert,
        [remarkToc, { maxDepth: 3, heading: "目次" }],
      ]}
      rehypePlugins={[rehypeSlug]}
      components={{
        img: ({ ...props }) => <Image {...props} />,
        code: CodeBlock,
      }}
    >
      {document || ""}
    </ReactMarkdown>
  </Container>
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    minHeight: "100vh",
    padding: {
      xs: 0,
      sm: 2,
      md: 4,
    },
  }),
);

const EmptyContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
  }),
);

const Image = styled("img")(({ theme }) =>
  theme.unstable_sx({
    maxWidth: {
      xs: "100%",
      sm: "100%",
      md: "100%",
      lg: "90%",
      xl: "70%",
    },
  }),
);

export default DocumentContent;
