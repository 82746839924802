import { Box, Link, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";

const LineContact: FC = () => {
  return (
    <Container>
      <Title>{"お問い合わせ"}</Title>
      <ContactContainer>
        <SectionTitle>{"Lineでのお問い合わせ"}</SectionTitle>
        <SectionDescription sx={{ marginTop: 4, whiteSpace: "pre-line" }}>
          {"お問い合わせの前に"}
          <Link href="/docs/faq">「FAQ よくあるご質問」</Link>
          {"をご覧ください"}
        </SectionDescription>

        <SectionDescription
          sx={{ marginTop: 4, whiteSpace: "pre-line" }}
        >{`以下のQRコードを読み取り、友達追加後にお問い合わせ内容の送信をお願いします。\n通常1~2営業日以内に返答させていただきます。`}</SectionDescription>

        <img
          style={{ marginTop: 24 }}
          width={200}
          height={200}
          src="https://qr-official.line.me/gs/M_423phnun_GW.png?oat_content=qr"
        ></img>
      </ContactContainer>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    marginBottom: 8,
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 4,
      sm: 4,
      md: 8,
    },
    textAlign: "center",
    fontSize: 36,
    fontWeight: "bold",
  }),
);

const ContactContainer = styled(Paper)(({ theme }) =>
  theme.unstable_sx({
    padding: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
);

const SectionTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
  }),
);

const SectionDescription = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    fontSize: 16,
  }),
);

export default LineContact;
