import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { del } from "aws-amplify/api";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useFetchPayJPCustomerInfo } from "../hooks/usePayJP";
import { snackbarState } from "../recoil/snackbar";
import { userState } from "../recoil/user";

export default function AccountSettings() {
  const navigate = useNavigate();

  const user = useRecoilValue(userState);
  const {
    customer,
    subscription,
    query: fetchPayJPCustomerInfoQuery,
  } = useFetchPayJPCustomerInfo(user?.customerId ?? null);
  const isActiveSubscription =
    subscription?.status === "active" || subscription?.status === "trial";
  const setSnackbarState = useSetRecoilState(snackbarState);

  const mutation = useMutation(
    async () => {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      await del({
        apiName: "MetalifyAccountAPI",
        path: "/api/account",
        options: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        },
      }).response;
    },
    {
      onSuccess: async () => {
        await signOut();
        navigate("/");
        window.location.reload();
      },
      onError: (err) => {
        setSnackbarState({
          open: true,
          message:
            "アカウントの削除に失敗しました。管理者にお問い合わせください。",
          severity: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
      },
    },
  );

  const deleteAccount = async () => {
    if (
      window.confirm(
        "[警告] アカウントを削除すると、二度と元に戻せません。アカウントを削除しますか？",
      )
    ) {
      mutation.mutate();
    }
  };

  const planName = useMemo(() => {
    const subscription = customer?.subscriptions.data[0] || null;
    if (subscription?.plan.id === "pln_efe2bf29b41925561491b382af21") {
      if (subscription?.status === "canceled")
        return "スタンダードプラン(キャンセル中)";
      return "スタンダードプラン";
    }
    return "なし";
  }, [customer]);

  if (fetchPayJPCustomerInfoQuery.isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Title>{"アカウント情報"}</Title>
      <Panel sx={{ marginTop: 2 }}>
        <FormContainer sx={{ marginTop: 1 }}>
          <FormLabel>{"メールアドレス"}</FormLabel>
          <FormValue>{user?.email || ""}</FormValue>
        </FormContainer>
        <FormContainer sx={{ marginTop: 1 }}>
          <FormLabel>{"プラン"}</FormLabel>
          <FormValue>{planName}</FormValue>
        </FormContainer>
      </Panel>
      <Title sx={{ marginTop: 8, color: "darkred" }}>{"アカウント削除"}</Title>
      <Panel sx={{ marginTop: 2 }}>
        <DeleteDescription>
          {
            "アカウントを削除すると、アカウントに関連するすべてのデータが削除されます。"
          }
        </DeleteDescription>
        <DeleteDescription>
          {"ご利用中のプランは強制的に解約され以降のご利用ができなくなります。"}
        </DeleteDescription>
        <DeleteDescription>
          {"削除されたアカウントは二度と復旧することはできません。"}
        </DeleteDescription>

        {isActiveSubscription && (
          <ErrorText>
            {
              "契約中のプランがあります。プランをキャンセルしてからアカウントを削除してください。"
            }
          </ErrorText>
        )}
        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
          <DeleteButton
            variant="contained"
            loading={mutation.isLoading}
            disabled={isActiveSubscription}
            sx={{ marginTop: 4 }}
            onClick={deleteAccount}
          >
            {"アカウントを削除する"}
          </DeleteButton>
        </Box>
      </Panel>
    </Container>
  );
}

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 2,
    marginBottom: 2,
    fontSize: 20,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    padding: {
      xs: 2,
      sm: 4,
      md: 4,
    },
  }),
);

const Panel = styled(Card)(({ theme }) =>
  theme.unstable_sx({
    padding: 4,
  }),
);

const FormContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "row",
  }),
);

const FormLabel = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 16,
    fontWeight: "bold",
    color: "#393f4c",
    flex: 1,
  }),
);

const FormValue = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 16,
    fontWeight: "bold",
    color: "#393f4c",
    flex: 1,
  }),
);

const DeleteDescription = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 14,
    color: "#393f4c",
  }),
);

const ErrorText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 14,
    color: "darkred",
    fontWeight: "bold",
  }),
);

const DeleteButton = styled(LoadingButton)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 16,
    backgroundColor: "darkred",
  }),
);
