import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const LandingFAQ: React.FC<Props> = () => {
  const faqs = [
    {
      question: "月額費用以外に追加でかかる費用はありますか？",
      answer:
        "Metalifyは月額980円のサブスクリプションサービスです。その他の費用は一切かかりません。",
    },
    {
      question: "サブスクを購入したらすぐに始められますか？",
      answer:
        "はい。プランを購入後すぐにMetalifyをご利用いただけるようになります。",
    },
    {
      question: "自分のPCで動かない場合はどうすればいいですか？",
      answer:
        "カスタマーサポートにご連絡いただければサポートチームが全力でサポートいたします。それでもご利用できない場合は、ご利用分を全額返金させていただきます。",
    },
    {
      question: "Windows以外のOSでの使用は可能ですか？",
      answer:
        "本サービスはWindowsのみサポートしております。MacOSやLinuxなどのOSは現在サポートしておりません。今後の対応については検討しております。",
    },
  ];

  return (
    <Container>
      <Title>{"よくあるご質問"}</Title>
      {faqs.map((faq) => (
        <FAQContainer>
          <FAQQuestion>{`Q. ${faq.question}`}</FAQQuestion>
          <FAQAnswer>{`A. ${faq.answer}`}</FAQAnswer>
        </FAQContainer>
      ))}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: {
      xs: 2,
      sm: 4,
      md: 4,
      lg: 4,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
  }),
);

const FAQContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#393f4c",
    borderRadius: 2,
    width: "100%",
    padding: {
      xs: 2,
      sm: 2,
      md: 4,
      lg: 4,
    },
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    gap: {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 2,
    },
  }),
);

const FAQQuestion = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
    fontWeight: "bold",
  }),
);

const FAQAnswer = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
  }),
);

export default LandingFAQ;
