import { SvgIconComponent } from "@mui/icons-material";
import {
  Icon,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import { FC } from "react";

interface Props {
  icon: SvgIconComponent;
  title: string;
  description: string;
  filename: string;
  onDownload?: (filename: string) => void;
}

const DownloadPanel: FC<Props> = ({
  icon: LogoIcon,
  title,
  description,
  filename,
  onDownload,
}) => {
  return (
    <Paper style={{ padding: 30 }}>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <LogoIcon sx={{ fontSize: 52 }} />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", color: "#393f4c" }}
          >
            {title}
          </Typography>
          <Typography style={{ fontSize: 15, color: "#455765" }}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Tooltip
            title={<Typography style={{ fontSize: 15 }}>{filename}</Typography>}
            placement="top"
          >
            <IconButton onClick={() => onDownload?.(filename)}>
              <Icon style={{ fontSize: 40 }}>download</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DownloadPanel;
