import { Box } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import MainHeader from "../components/Home/MainHeader";
import LandingBenefit from "../components/Landing/LandingBenefit";
import LandingClosing from "../components/Landing/LandingClosing";
import LandingCustomersVoice from "../components/Landing/LandingCustomersVoice";
import LandingFAQ from "../components/Landing/LandingFAQ";
import LandingIntroVideo from "../components/Landing/LandingIntroVideo";
import LandingService from "../components/Landing/LandingService";
import { styled } from "@mui/system";

const Home: FC = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate("/sign?state=signUp");
  };
  return (
    <Container>
      <MainHeader onClickGetStarted={handleGetStarted} />
      <LandingService />
      <LandingIntroVideo />
      <LandingBenefit />
      <LandingCustomersVoice />
      <LandingFAQ />
      <LandingClosing onClickGetStarted={handleGetStarted} />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
    gap: {
      xs: 8,
      sm: 8,
      md: 8,
      lg: 12,
    },
  }),
);

export default Home;
