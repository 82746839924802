import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const LandingCustomersVoice: React.FC<Props> = () => {
  const voices = [
    {
      attribute: "45歳 男性 トレーダー歴15年",
      title: "私のトレードスキル向上の\n秘密兵器",
      content:
        "Metalifyを利用してから、トレードスキルが飛躍的に向上したのを感じています。特に高速再生機能がお気に入りで、過去の相場を高速で再生しながらエントリーポイントを見つける練習が非常に効果的です。このソフトは私の秘密兵器となり、今では自信を持って本トレードに臨むことができます。価格以上の価値があると思います。",
      img: "/img/customer_voice_person2.png",
    },
    {
      attribute: "24歳 男性 トレーダー歴3年",
      title: "忙しい人にもぴったり",
      content:
        "私は忙しい仕事があるため、FXの練習に十分な時間を割けません。しかし、Metalifyはいつでも利用できるので、空いた時間に練習できる点が素晴らしいと思います。仕事の休憩などの隙間時間に練習することができ、相場観が着実に向上しています。忙しい人には最適なツールです。",
      img: "/img/customer_voice_person1.png",
    },
    {
      attribute: "30歳 女性 トレーダー歴7年",
      title: "サポートが親切かつ丁寧でした",
      content:
        "Metalifyを購入後、操作に少し苦労しましたが、カスタマーサポートに問い合わせたところ、驚くほど迅速かつ丁寧なサポートを受けました。問題を素早く解決していただき、使い方のコツも教えてもらえました。サポートチームに感謝しています。",
      img: "/img/customer_voice_person3.png",
    },
  ];

  return (
    <Container>
      <Title>{"\\ご利用者様の声/"}</Title>
      {voices.map((voice) => (
        <VoiceContainer>
          <VoiceImage src={voice.img} alt="landing_voice" />
          <VoiceAttribute>{voice.attribute}</VoiceAttribute>
          <VoiceTitle>{voice.title}</VoiceTitle>
          <VoiceContent>{voice.content}</VoiceContent>
        </VoiceContainer>
      ))}
      <Description>
        {
          "*ご利用者様の声はMetalifyのベータ版を利用いただいた方のレビューになります。プライバシー保護のため顔写真はイメージ画像とさせていただいております。"
        }
      </Description>
    </Container>
  );
};

const VoiceImage = styled("img")(({ theme }) =>
  theme.unstable_sx({
    width: 100,
    height: 100,
    borderRadius: 50,
  }),
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: {
      xs: 2,
      sm: 4,
      md: 4,
      lg: 4,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 14,
      sm: 16,
      md: 18,
    },
  }),
);

const VoiceContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    borderRadius: 2,
    borderColor: "#393f4c",
    borderWidth: 1,
    borderStyle: "solid",
    width: "100%",
    padding: {
      xs: 2,
      sm: 2,
      md: 4,
      lg: 4,
    },
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
    },
  }),
);

const VoiceTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 20,
      sm: 24,
      md: 28,
    },
    fontWeight: "bold",
  }),
);

const VoiceContent = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
  }),
);

const VoiceAttribute = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
  }),
);

export default LandingCustomersVoice;
