import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import LandingBenefit from "../components/Landing/LandingBenefit";
import LandingConcern from "../components/Landing/LandingConcern";
import LandingCustomersVoice from "../components/Landing/LandingCustomersVoice";
import LandingFAQ from "../components/Landing/LandingFAQ";
import LandingHeader from "../components/Landing/LandingHeader";
import LandingIntroVideo from "../components/Landing/LandingIntroVideo";
import LandingService from "../components/Landing/LandingService";
import LandingClosing from "../components/Landing/LandingClosing";

const Landing: FC = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate("/sign?state=signUp");
  };
  return (
    <Container>
      <LandingHeader onClickGetStarted={handleGetStarted} />
      <LandingConcern />
      <LandingService />
      <LandingBenefit />
      <LandingCustomersVoice />
      <LandingFAQ />
      <LandingIntroVideo />
      <LandingClosing onClickGetStarted={handleGetStarted} />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    gap: {
      xs: 8,
      sm: 8,
      md: 8,
      lg: 12,
    },
    marginBottom: 8,
  }),
);

export default Landing;
