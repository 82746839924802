import { Box, BoxProps, Button, ButtonProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {
  onClickGetStarted?: () => void;
}

const LandingHeader: React.FC<Props> = ({ onClickGetStarted }) => {
  return (
    <HeaderContainer>
      <Title>{"最短で効率よく\n稼ぐトレーダーへ"}</Title>
      <Description>{"MetaTrader4対応\nFX練習ソフト"}</Description>
      <PriceContainer sx={{ marginTop: 2 }}>
        <PriceTitle>{"月額\n料金"}</PriceTitle>
        <PriceTextContainer>
          <PriceDiscriptionHint>たったの</PriceDiscriptionHint>
          <PriceDiscription>980円</PriceDiscription>
        </PriceTextContainer>
        <LogoAward
          sx={{
            marginLeft: {
              xs: 2,
              sm: 4,
              md: 8,
            },
          }}
          src="/img/logo_award.png"
          alt="logo_award"
        />
      </PriceContainer>
      <GetStartedButton
        sx={{
          marginTop: 2,
        }}
        onClick={onClickGetStarted}
      >
        {"14日無料体験をする"}
      </GetStartedButton>
    </HeaderContainer>
  );
};

const LogoAward = styled("img")(({ theme }) =>
  theme.unstable_sx({
    width: {
      xs: 80,
      sm: 100,
      md: 120,
      lg: 140,
    },
    height: {
      xs: 80,
      sm: 100,
      md: 120,
      lg: 140,
    },
  }),
);

const HeaderContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: {
      xs: 'url("/img/landing_header4.png")',
      sm: 'url("/img/landing_header_large.png")',
    },
    backgroundSize: "cover",
    padding: 4,
    gap: 2,
    paddingTop: {
      xs: 8,
      sm: 8,
      md: 8,
    },
    paddingBottom: {
      xs: 8,
      sm: 8,
      md: 8,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "white",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 36,
      sm: 52,
      md: 64,
      lg: 72,
    },
    fontWeight: "bold",
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "white",
    whiteSpace: {
      xs: "pre-line",
      sm: "pre-line",
      md: "normal",
    },
    fontSize: {
      xs: 24,
      sm: 26,
      md: 30,
    },
  }),
);

const PriceContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
  }),
);

const PriceTextContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
);

const PriceTitle = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      backgroundColor: "#3cb371",
      borderRadius: 20,
      width: {
        xs: 64,
        sm: 80,
        md: 96,
      },
      height: {
        xs: 64,
        sm: 80,
        md: 96,
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...props.sx,
    }}
  >
    <Typography
      sx={{
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        fontSize: {
          xs: 16,
          sm: 20,
          md: 24,
        },
      }}
    >
      {props.children}
    </Typography>
  </Box>
);

const PriceDiscriptionHint = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "white",
    whiteSpace: "pre-line",
    fontSize: {
      xs: 16,
      sm: 20,
      md: 24,
    },
    fontWeight: "bold",
  }),
);

const PriceDiscription = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "orange",
    whiteSpace: "pre-line",
    lineHeight: 1,
    fontSize: {
      xs: 32,
      sm: 40,
      md: 48,
    },
    fontWeight: "bold",
  }),
);

const GetStartedButton = (props: ButtonProps) => (
  <Button
    {...props}
    sx={{
      fontSize: {
        xs: 20,
        sm: 24,
        md: 28,
      },
      textTransform: "none",
      fontWeight: "bold",
      backgroundColor: "#f08300",
      padding: 2,
      paddingLeft: {
        xs: 4,
        sm: 4,
        md: 8,
        lg: 8,
      },
      paddingRight: {
        xs: 4,
        sm: 4,
        md: 8,
        lg: 8,
      },
      ...props.sx,
    }}
    variant="contained"
    disableElevation
  >
    <Box>
      <Typography
        sx={{
          fontSize: 16,
        }}
      >
        \今だけ/
      </Typography>
      {props.children}
    </Box>
  </Button>
);

export default LandingHeader;
