import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { useQuery } from "react-query";
import { Customer } from "../types/payjp";
import { Nullable } from "../types/helper";

export const useFetchPayJPCustomerInfo = (customerId: Nullable<string>) => {
  const getCustomerQuery = useQuery({
    queryFn: async () => {
      const { tokens } = await fetchAuthSession();
      if (!tokens) throw new Error("No tokens");
      const authToken = tokens.idToken;
      const response = await get({
        apiName: "MetalifyPlanAPI",
        path: `/api/plan/customer/${customerId}`,
        options: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      }).response;
      const customer = ((await response.body.json()) ||
        null) as Customer | null;
      return customer;
    },
    retry: false,
    enabled: customerId !== null,
  });

  const customer = getCustomerQuery.data ?? null;
  const subscription = customer?.subscriptions.data[0] || null;

  return {
    customer,
    subscription,
    query: getCustomerQuery,
  };
};
