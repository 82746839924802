import React, { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { useMutation } from "react-query";
import { generateClient } from "aws-amplify/api";
import * as gqlQueries from "../../graphql/queries"; // create, update, delete
import { User } from "../../API";
import { useSetRecoilState } from "recoil";
import { userState } from "../../recoil/user";

interface Props {
  children: React.ReactNode;
}
export default function UserAuth({ children }: Props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  const getUserMutation = useMutation(
    async (id: string) => {
      const client = generateClient({});
      const result: any = await client.graphql({
        query: gqlQueries.getUser,
        variables: {
          id: id,
        },
      });
      const user: User | null = result.data.getUser || null;
      return user;
    },
    {
      onSuccess: (user) => {
        setUser(user);
      },
      onError: (error) => {
        navigate("/sign");
      },
    },
  );

  useEffect(() => {
    if (user) {
      getUserMutation.mutate(user.username);
    }
  }, [user]);

  if (!user || getUserMutation.isLoading) {
    return <LinearProgress />;
  }

  return <div>{children}</div>;
}
