import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {
  onClickGetStarted?: () => void;
}

const LandingClosing: React.FC<Props> = ({ onClickGetStarted }) => {
  return (
    <Container>
      <Title>
        まずは<span>14日無料体験</span>へ
      </Title>
      <Description>
        {
          "Metalifyはじっくりお試しいただいた上で、使い続けるかどうかを判断していただきたいので、無料トライアル期間を長めにご用意しています。まずはお気軽にお試しください。"
        }
      </Description>
      <GetStartedButton
        sx={{
          marginTop: 2,
        }}
        onClick={onClickGetStarted}
      >
        {"14日無料体験をする"}
      </GetStartedButton>
    </Container>
  );
};

const GetStartedButton = (props: ButtonProps) => (
  <Button
    {...props}
    sx={{
      fontSize: {
        xs: 20,
        sm: 24,
        md: 28,
      },
      textTransform: "none",
      fontWeight: "bold",
      backgroundColor: "#f08300",
      padding: 2,
      paddingLeft: {
        xs: 4,
        sm: 4,
        md: 8,
        lg: 8,
      },
      paddingRight: {
        xs: 4,
        sm: 4,
        md: 8,
        lg: 8,
      },
      ...props.sx,
    }}
    variant="contained"
    disableElevation
  >
    <Box>
      <Typography
        sx={{
          fontSize: 16,
        }}
      >
        \ 簡単5分！ /
      </Typography>
      {props.children}
    </Box>
  </Button>
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: 2,
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
    span: {
      color: "red",
      fontSize: {
        xs: 32,
        sm: 40,
        md: 48,
      },
    },
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
    },
  }),
);

export default LandingClosing;
