import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const LandingBenefit: React.FC<Props> = () => {
  const splitArray = <T,>(array: T[], part: number) => {
    const tmp = [];
    for (let i = 0; i < array.length; i += part) {
      tmp.push(array.slice(i, i + part));
    }
    return tmp;
  };

  const theme = useTheme();
  const isUpperThanMd = useMediaQuery(theme.breakpoints.up("md"));

  const benefits = [
    {
      title: "１. いつでも練習できる",
      description:
        "Metalifyでは過去のデータを使って練習するので、市場が閉じている土日でも練習できます。",
      img: "/img/undraw_typewriter.svg",
    },
    {
      title: "２. 短時間で技術が身に付く",
      description:
        "売買チャンスがなけれぼチャートを早送りできるため、Metalifyなら効率よく練習ができます。",
      img: "/img/undraw_investing_re_bov7.svg",
    },
    {
      title: "３. 本番と同じ取引ができる",
      description:
        "MT4インジケーターを使用したり、複数のチャートの同時再生ができるため、本番環境と変わらない実践的な練習ができます。",
      img: "/img/undraw_programming_re_kg9v.svg",
    },
    {
      title: "４. 低価格で始めやすい",
      description:
        "月額980円のサブスクなので誰でも気軽に始められます。その他の費用は一切かかりません。",
      img: "/img/undraw_savings_re_eq4w.svg",
    },
  ];

  return (
    <Container>
      <Title>{"Metalifyが選ばれる4つの理由"}</Title>
      <BenefitsContainer>
        {isUpperThanMd &&
          splitArray(benefits, 2).map(([benefit1, benefit2], index) => (
            <RowContainer>
              <BenefitContainer>
                <BenefitTitle>{benefit1.title}</BenefitTitle>
                <ConcernImage src={benefit1.img} alt="landing_concern" />
                <BenefitDescription>{benefit1.description}</BenefitDescription>
              </BenefitContainer>
              <BenefitContainer>
                <BenefitTitle>{benefit2.title}</BenefitTitle>
                <ConcernImage src={benefit2.img} alt="landing_concern" />
                <BenefitDescription>{benefit2.description}</BenefitDescription>
              </BenefitContainer>
            </RowContainer>
          ))}
        {!isUpperThanMd &&
          benefits.map((benefit, index) => (
            <BenefitContainer key={index}>
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <ConcernImage src={benefit.img} alt="landing_concern" />
              <BenefitDescription>{benefit.description}</BenefitDescription>
            </BenefitContainer>
          ))}
      </BenefitsContainer>
    </Container>
  );
};

const ConcernImage = styled("img")(({ theme }) =>
  theme.unstable_sx({
    width: "70%",
  }),
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: {
      xs: 2,
      sm: 4,
      md: 4,
      lg: 4,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
  }),
);

const RowContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: {
      xs: 2,
      sm: 4,
      md: 4,
      lg: 4,
    },
    height: {
      xs: "auto",
      sm: "auto",
      md: 550,
      lg: 550,
    },
  }),
);

const BenefitsContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
    },
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 2,
    },
  }),
);

const BenefitContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: {
      xs: 2,
      sm: 4,
      md: 4,
      lg: 4,
    },
    borderRadius: 2,
    borderColor: "#393f4c",
    borderWidth: 1,
    borderStyle: "solid",
    width: "100%",
    height: "100%",
    padding: {
      xs: 2,
      sm: 4,
      md: 2,
      lg: 2,
    },
  }),
);

const BenefitTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 20,
      sm: 24,
      md: 28,
    },
    fontWeight: "bold",
  }),
);

const BenefitDescription = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 4,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 4,
    },
  }),
);

export default LandingBenefit;
