import { Typography, Box } from "@mui/material";
import DownloadPanel from "../components/Download/DownloadPanel";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import Alert from "@mui/material/Alert";
//import AppleIcon from "@mui/icons-material/Apple";

// need to upload file to s3 /protected/ap-northeast-1:xxx/
export default function Download() {
  const downloadEAPackages = async (filename: string) => {
    window.open(
      `https://github.com/RuiHirano/metalify-releases/releases/download/v${controllerVersion}/${filename}`,
    );
  };
  const controllerVersion = process.env.REACT_APP_METALIFY_CONTROLLER_VERSION;

  return (
    <div>
      <Alert severity="info">
        {
          "2024年8月に MetalifyController の Verson 2.1.0 がリリースされました。Version 2.0.0 以前のバージョンをご利用されていた場合は、最新のバージョンを以下のボタンから再ダウンロードしてください。"
        }
      </Alert>
      <Box sx={{ margin: { xs: 3, sm: 3, md: 5, lg: 5 } }}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", color: "#393f4c" }}
          >
            {"Windowsの方"}
          </Typography>
        </div>
        <div style={{ marginTop: 20 }}>
          <DownloadPanel
            icon={MicrosoftIcon}
            title={"Windows用 Metalify アプリ"}
            description={"Windows用のMetalify操作ツールです"}
            filename={`MetalifyController-${controllerVersion}.exe`}
            onDownload={downloadEAPackages}
          />
        </div>
        {/*<div style={{ marginTop: 40, marginBottom: 10 }}>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", color: "#393f4c" }}
          >
            {"Macの方"}
          </Typography>
        </div>
        <div style={{ marginTop: 20 }}>
          <DownloadPanel
            icon={AppleIcon}
            title={"Mac用 Metalify アプリ"}
            description={"Mac用のMetalify操作ツールです"}
            filename={`MetalifyController-${controllerVersion}.dmg`}
            onDownload={downloadEAPackages}
          />
  </div>*/}
      </Box>
      {/*<Box sx={{ margin: { xs: 3, sm: 3, md: 5, lg: 5 } }}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", color: "#393f4c" }}
          >
            {"Macの方"}
          </Typography>
        </div>
        <div style={{ marginTop: 20 }}>
          <DownloadPanel
            icon={AppleIcon}
            title={"Mac用 Metalify アプリ"}
            description={"MacOS用のMetalify操作ツールです"}
            filename={`MetalifyController-${controllerVersion}.dmg`}
            onDownload={downloadEAPackages}
          />
        </div>
  </Box>*/}
    </div>
  );
}
