import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useMutation } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useFetchPayJPCustomerInfo } from "../hooks/usePayJP";
import { CheckoutCreatedResponse, PayjpCheckout } from "../lib/PayjpCheckout";
import { snackbarState } from "../recoil/snackbar";
import { userState } from "../recoil/user";
import Alert from "@mui/material/Alert";

export const Plan = () => {
  const setSnackbarState = useSetRecoilState(snackbarState);
  const user = useRecoilValue(userState);

  const [checkoutCreatedResponse, setCheckoutCreatedResponse] =
    useState<CheckoutCreatedResponse | null>(null);
  const [isSuccessSubscribe, setIsSuccessSubscribe] = useState<boolean>(false);

  const { subscription, query: getCustomerQuery } = useFetchPayJPCustomerInfo(
    user?.customerId ?? null,
  );

  const createCustomerMutate = useMutation(async (token: string) => {
    const { tokens } = await fetchAuthSession();
    if (!tokens) throw new Error("No tokens");
    const authToken = tokens.idToken;
    await post({
      apiName: "MetalifyPlanAPI",
      path: "/api/plan/customer",
      options: {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        queryParams: {
          token: token,
        },
      },
    }).response;
  });

  const createSubscriptionMutate = useMutation(
    async () => {
      const { tokens } = await fetchAuthSession();
      if (!tokens) throw new Error("No tokens");
      const authToken = tokens.idToken;
      await post({
        apiName: "MetalifyPlanAPI",
        path: "/api/plan/subscription",
        options: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      }).response;
    },
    {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: "プランを購入しました",
          severity: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
        setIsSuccessSubscribe(true);
        getCustomerQuery.refetch();
      },
      onError: () => {
        setSnackbarState({
          open: true,
          message: "プランのリクエストに失敗しました",
          severity: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
      },
    },
  );

  const cancelSubscriptionMutate = useMutation(
    async (subscriptionId: string) => {
      const { tokens } = await fetchAuthSession();
      if (!tokens) throw new Error("No tokens");
      const authToken = tokens.idToken;
      await post({
        apiName: "MetalifyPlanAPI",
        path: "/api/plan/subscription/cancel",
        options: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          queryParams: {
            subscription_id: subscriptionId,
          },
        },
      }).response;
    },
    {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: "プランをキャンセルしました",
          severity: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
        getCustomerQuery.refetch();
      },
      onError: (err) => {
        console.error("error: ", err);
        setSnackbarState({
          open: true,
          message: "プランのキャンセルに失敗しました",
          severity: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
      },
    },
  );

  const resumeSubscriptionMutate = useMutation(
    async (subscriptionId: string) => {
      const { tokens } = await fetchAuthSession();
      if (!tokens) throw new Error("No tokens");
      const authToken = tokens.idToken;
      await post({
        apiName: "MetalifyPlanAPI",
        path: "/api/plan/subscription/resume",
        options: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          queryParams: {
            subscription_id: subscriptionId,
          },
        },
      }).response;
    },
    {
      onSuccess: () => {
        setSnackbarState({
          open: true,
          message: "プランを再開しました",
          severity: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
        getCustomerQuery.refetch();
      },
      onError: () => {
        setSnackbarState({
          open: true,
          message: "プランの再開に失敗しました",
          severity: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          onClose: () => setSnackbarState(null),
        });
      },
    },
  );

  const requestPlan = async () => {
    if (window.confirm("プランを購入します。よろしいですか？")) {
      await createSubscriptionMutate.mutateAsync();
    }
  };

  const cancelPlan = async () => {
    if (subscription) {
      if (
        window.confirm(
          "プランをキャンセルします。キャンセル後は期間終了日までご利用できます。よろしいですか？",
        )
      ) {
        await cancelSubscriptionMutate.mutateAsync(subscription.id);
      }
    }
  };

  const resumePlan = async () => {
    if (subscription) {
      if (
        window.confirm(
          "プランを再開します。再開後は期間終了後から定期課金が発生します。よろしいですか？",
        )
      ) {
        await resumeSubscriptionMutate.mutateAsync(subscription.id);
      }
    }
  };

  const checkoutButtonRef = useRef<HTMLButtonElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (getCustomerQuery.isLoading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ margin: { xs: 3, sm: 3, md: 5, lg: 5 } }}>
      <SectionTitle>{"ご利用中のプラン"}</SectionTitle>
      {subscription === null && (
        <EmptyText>{"現在利用しているプランはありません"}</EmptyText>
      )}
      {subscription && (
        <PlanPanel>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                <PlanStatus>
                  {subscription.status === "trial"
                    ? "トライアル中"
                    : subscription.status === "canceled"
                    ? "キャンセル"
                    : subscription.status === "active"
                    ? "継続中"
                    : "-"}
                </PlanStatus>
                <PlanTitle>{"Metalify Standard Plan"}</PlanTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <IconButton onClick={handleClick} aria-label="Settings">
                  <SettingsIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  {subscription.status !== "canceled" && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        cancelPlan();
                      }}
                    >
                      <ListItemIcon>
                        <PauseIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>プランをキャンセルする</ListItemText>
                    </MenuItem>
                  )}
                  {subscription.status === "canceled" && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        resumePlan();
                      }}
                    >
                      <ListItemIcon>
                        <PlayArrowIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>プランを再開する</ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
            {subscription.status === "active" && (
              <PlanFee>{"月額980円"}</PlanFee>
            )}
            {subscription.status === "trial" && (
              <PlanFee>{"14日間無料トライアル"}</PlanFee>
            )}
            {subscription.status === "trial" && (
              <PlanDescription>
                {"無料トライアル終了後、月額980円"}
              </PlanDescription>
            )}
            {subscription.status === "trial" && (
              <PlanPeriodText>{`トライアル期間：${DateTime.fromSeconds(
                subscription.trial_start,
              ).toFormat("yyyy/MM/dd")} ~ ${DateTime.fromSeconds(
                subscription.trial_end,
              ).toFormat("yyyy/MM/dd")}`}</PlanPeriodText>
            )}
            {subscription.status === "active" && (
              <PlanPeriodText>{`期間：${DateTime.fromSeconds(
                subscription.current_period_start,
              ).toFormat("yyyy/MM/dd")} ~ ${DateTime.fromSeconds(
                subscription.current_period_end,
              ).toFormat("yyyy/MM/dd")}`}</PlanPeriodText>
            )}
            {subscription.status === "canceled" && (
              <PlanPeriodText>{`プランはキャンセルされています。 ${DateTime.fromSeconds(
                subscription.current_period_end,
              ).toFormat(
                "yyyy/MM/dd",
              )}まで利用できます。プランはメニューからいつでも再開できます。`}</PlanPeriodText>
            )}
          </Box>
        </PlanPanel>
      )}
      {subscription == null && (
        <Box>
          <SectionTitle sx={{ marginTop: 4 }}>{"プランの申請"}</SectionTitle>
          <PlanPanel>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                },
                display: "flex",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <PlanStatus>{"Standard"}</PlanStatus>
                <PlanTitle>{"Metalify Standard Plan"}</PlanTitle>
                <PlanFee>{"14日間無料トライアル"}</PlanFee>
                <PlanDescription>
                  {"無料トライアル終了後、月額980円"}
                </PlanDescription>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  marginTop: {
                    xs: 2,
                    sm: 0,
                    md: 0,
                    lg: 0,
                  },
                }}
              >
                <LoadingButton
                  variant="outlined"
                  ref={checkoutButtonRef}
                  loading={createCustomerMutate.isLoading}
                  disabled={checkoutCreatedResponse !== null}
                  sx={{
                    color: "#1f6feb",
                    borderColor: "#1f6feb",
                    "&.Mui-disabled": {
                      color: createCustomerMutate.isLoading ? "gray" : "green",
                      borderColor: createCustomerMutate.isLoading
                        ? "gray"
                        : "green",
                    },
                  }}
                >
                  {createCustomerMutate.isLoading
                    ? "確認中..."
                    : checkoutCreatedResponse
                    ? "カード情報入力済"
                    : "カード情報を入力する"}
                </LoadingButton>
                <PayjpCheckout
                  buttonRef={checkoutButtonRef}
                  publicKey={process.env.REACT_APP_PAYJP_PUBLIC_KEY || ""}
                  partial={true}
                  buttonText="プランを購入する"
                  language={"ja"}
                  onCreated={async (res) => {
                    // 顧客を作成
                    try {
                      await createCustomerMutate.mutateAsync(res.id);
                      setCheckoutCreatedResponse(res);
                    } catch (err) {
                      setSnackbarState({
                        open: true,
                        message:
                          "カード認証に失敗しました。管理者にお問い合わせしてください。",
                        severity: "error",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        onClose: () => setSnackbarState(null),
                      });
                    }
                  }}
                  onFailed={(statusCode) => {
                    setSnackbarState({
                      open: true,
                      message: `カード情報の認証に失敗しました。ステータスコード: ${statusCode}`,
                      severity: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      onClose: () => setSnackbarState(null),
                    });
                  }}
                />
                <LoadingButton
                  variant="contained"
                  loading={createSubscriptionMutate.isLoading}
                  sx={{ marginTop: 2 }}
                  disabled={
                    checkoutCreatedResponse === null || isSuccessSubscribe
                  }
                  onClick={requestPlan}
                >
                  {"プランを購入する"}
                </LoadingButton>

                {isSuccessSubscribe && (
                  <Alert severity="info" sx={{ marginTop: 2 }}>
                    {
                      "プラン購入後も画面が切り替わらない場合は、更新してください"
                    }
                  </Alert>
                )}
              </Box>
            </Box>
          </PlanPanel>
        </Box>
      )}
    </Box>
  );
};

const PlanPanel = styled(Card)(({ theme }) =>
  theme.unstable_sx({
    padding: 4,
  }),
);

const SectionTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 2,
    marginBottom: 2,
    fontSize: 20,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const PlanTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 1,
    fontSize: 24,
    fontWeight: "bold",
  }),
);

const PlanFee = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 1,
    fontSize: 20,
    fontWeight: "bold",
  }),
);

const PlanDescription = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 1,
    fontSize: 16,
  }),
);

const PlanPeriodText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    marginTop: 1,
    fontSize: 14,
  }),
);

const PlanStatus = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 12,
    fontWeight: "bold",
    width: "fit-content",
    paddingLeft: 1,
    paddingRight: 1,
    borderRadius: 1,
    backgroundColor: "#f08300",
    color: "white",
  }),
);

const EmptyText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    margin: 8,
    fontSize: 16,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);
