import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { Fragment, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useAuthenticator } from "@aws-amplify/ui-react";

export interface Props {
  title: string;
  onClickLogin?: () => void;
}

const HomeHeader: React.FC<Props> = ({ title, onClickLogin }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  const enterLabel = useMemo(() => {
    return route === "idle" ? "" : user ? "マイページ" : "ログイン";
  }, [user, route]);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // mdより小さい場合はmenu
  const theme = useTheme();
  const lessThanMid = useMediaQuery(theme.breakpoints.down("md"));

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate("/docs/started");
        }}
      >
        <ListItemIcon>
          <ImportContactsIcon />
        </ListItemIcon>
        <ListItemText>ドキュメント</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate("/pricing");
        }}
      >
        <ListItemIcon>
          <CurrencyYenIcon />
        </ListItemIcon>
        <ListItemText>ご利用料金</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate("/support/contact");
        }}
      >
        <ListItemIcon>
          <ContactSupportIcon />
        </ListItemIcon>
        <ListItemText>お問い合わせ</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          onClickLogin?.();
        }}
      >
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText>{enterLabel}</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "white" }}
        elevation={1}
      >
        <Toolbar>
          <Logo to="/">{title}</Logo>
          {/*<NavLink
            sx={{ marginLeft: 8 }}
            to="/docs/started"
          >
            {"製品"}
          </NavLink>*/}
          {/*<NavLink
            sx={{ marginLeft: 4 }}
            to="/docs/started"
          >
            {"料金体系"}
          </NavLink>*/}
          {!lessThanMid && (
            <Fragment>
              <NavLink sx={{ marginLeft: 4 }} to="/docs/started">
                {"ドキュメント"}
              </NavLink>
              <NavLink sx={{ marginLeft: 4 }} to="/pricing">
                {"ご利用料金"}
              </NavLink>
              <NavLink sx={{ marginLeft: 4 }} to="/support/contact">
                {"お問い合わせ"}
              </NavLink>
            </Fragment>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {lessThanMid && (
            <Box
              style={{
                marginLeft: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          {!lessThanMid && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={onClickLogin}
                disableElevation
                variant="contained"
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "#2e8555",
                }}
              >
                {enterLabel}
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
};

const Logo = styled(Link)(({ theme }) =>
  theme.unstable_sx({
    color: "#333631",
    fontSize: 24,
    fontWeight: "bold",
    textDecoration: "none",
  }),
);

const NavLink = styled(Link)(({ theme }) =>
  theme.unstable_sx({
    color: "#333631",
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "none",
  }),
);

export default HomeHeader;
