/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "MetalifyAccountAPI",
            "endpoint": "https://wfo5zc5lue.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "MetalifyContactAPI",
            "endpoint": "https://v4mbw987d9.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "MetalifyPlanAPI",
            "endpoint": "https://ztosj1cle1.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "MetalifyUserAPI",
            "endpoint": "https://7qxcwgxwf4.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://wrshhhah6ndftean5lythigfoa.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:db471b6f-3dd9-4e8f-bf90-26481c6a05d2",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_XVZeRXKXK",
    "aws_user_pools_web_client_id": "5betm7cs8nhgf3f7ecom1r7df9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "metalify0c4da7107cf44cb787991c992e19e882a27d9-production",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
