import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";
import UserLayout from "./layouts/UserLayout";
import AccountSettings from "./pages/AccountSettings";
//import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Docs from "./pages/Docs";
import Download from "./pages/Download";
import FAQ from "./pages/FAQ";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import LineContact from "./pages/LineContact";
import NotFound from "./pages/NotFound";
import { Plan } from "./pages/Plan";
import Pricing from "./pages/Pricing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SCT from "./pages/SCT";
import Sign from "./pages/Sign";
import TermsOfService from "./pages/TermsOfService";

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/lp" element={<Landing />} />
          <Route path="/sign" element={<Sign />} />
          <Route path="/terms/terms-of-service" element={<TermsOfService />} />
          <Route path="/terms/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms/sct" element={<SCT />} />
          <Route path="/support/contact" element={<LineContact />} />
          <Route path="/support/faq" element={<FAQ />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/docs/:name" element={<Docs />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/console" element={<UserLayout />}>
          <Route path="/console" element={<Dashboard />} />
          {/*<Route path="/console/backtests" element={<Backtests />} />*/}
          {/*<Route path="/console/analysis/:id" element={<Analysis />} />*/}
          <Route path="/console/download" element={<Download />} />
          <Route path="/console/plan" element={<Plan />} />
          <Route
            path="/console/account-settings"
            element={<AccountSettings />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
