import { FC, RefObject, useEffect, useRef, useState } from "react";

export interface CheckoutCreatedResponse {
  id: string;
  livemode: boolean;
  created: number;
  object: string;
  used: boolean;
  card: {
    id: string;
    object: string;
    last4: string;
    brand: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    country: string;
    name: string;
    address_zip: string;
    address_state: string;
    address_city: string;
    address_line1: string;
    address_line2: string;
    address_zip_check: string;
    customer: string;
    cvc_check: string;
  };
}

export interface CheckoutFailedResponse {
  code: string;
  message: string;
  status: number;
  type: string;
}

interface Props {
  buttonRef: RefObject<HTMLButtonElement>; // カード情報入力フォームを開くのボタンのref
  publicKey: string; // Pay.jp public key
  partial?: boolean; // カード情報入力フォームを開くのボタンを表示しない
  buttonText?: string; // カード情報入力フォームを開くのボタンのテキスト
  submitText?: string; // カード情報入力フォーム内の送信ボタンのテキスト
  language?: "ja" | "en" | null; // メッセージなどの表示言語
  namePlaceholder?: string; // カード名義のplaceholderにセットされる値
  onCreated?: (response: CheckoutCreatedResponse) => void;
  onFailed?: (
    statusCode: string,
    errorResponse: CheckoutFailedResponse,
  ) => void;
}

/*
 * Pay.jp Checkout Component
 * @param {string} publicKey - Pay.jp public key
 * @param {string} buttonText - Button text to open card information input form
 * @param {string} submitText - Submit button text
 * @param {string} language - Language of checkout
 * @param {string} namePlaceholder - Placeholder of card name
 * @param {function} onCreated - Callback function when checkout created
 * @param {function} onFailed - Callback function when checkout failed
 * set <script src="https://js.pay.jp/v2/pay.js"></script> in index.html
 */
export const PayjpCheckout: FC<Props> = ({
  buttonRef,
  publicKey,
  partial,
  buttonText,
  submitText,
  language,
  namePlaceholder,
  onCreated,
  onFailed,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [button, setButton] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    const timerId = setInterval(() => {
      const checkoutBoxNode = document.getElementById("payjp_checkout_box");
      if (checkoutBoxNode) {
        const button = checkoutBoxNode.getElementsByTagName("input")[0];
        if (button) {
          setButton(button);
          clearInterval(timerId);
        }
      }
    }, 100);
  }, [document]);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current?.addEventListener("click", () => {
        if (button) {
          button.click();
        }
      });
    }
  }, [button]);

  useEffect(() => {
    (window as any).reactPayjpCheckoutOnCreated = (
      res: CheckoutCreatedResponse,
    ) => {
      onCreated?.(res);
    };

    (window as any).reactPayjpCheckoutOnFailed = (
      statusCode: string,
      errorResponse: any,
    ) => {
      onFailed?.(statusCode, errorResponse);
    };
    const script = document.createElement("script");
    script.setAttribute("src", "https://checkout.pay.jp/");
    script.setAttribute("class", "payjp-button");
    script.setAttribute("data-key", publicKey);
    partial && script.setAttribute("data-partial", partial.toString());
    buttonText && script.setAttribute("data-text", buttonText);
    submitText && script.setAttribute("data-submit-text", submitText);
    language && script.setAttribute("data-lang", language);
    namePlaceholder &&
      script.setAttribute("data-name-placeholder", namePlaceholder);
    script.setAttribute("data-on-created", "reactPayjpCheckoutOnCreated");
    script.setAttribute("data-on-failed", "reactPayjpCheckoutOnFailed");
    ref?.current?.appendChild(script);
    return () => {
      ref?.current?.removeChild(script);
      // @ts-ignore
      window.PayjpCheckout = null;
    };
  }, [ref]);

  return <div style={{ display: "none" }} ref={ref}></div>;
};
