import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const LandingIntroVideo: React.FC<Props> = () => {
  return (
    <Container>
      <Title>{"Metalifyを動画でみる"}</Title>
      <Description>
        {
          "Metalifyを実際に利用した際の動画です。\nMT4を使った基本的な操作を説明しています。"
        }
      </Description>
      <Video poster={"img/metalify_logo.png"} controls sx={{ marginTop: 2 }}>
        <source
          src={
            "https://metalify0c4da7107cf44cb787991c992e19e882210751-prod.s3.ap-northeast-1.amazonaws.com/public/metalify_introduction.mp4"
          }
          type="video/mp4"
        />
        Sorry, your browser doesn't support embedded videos.
      </Video>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: 2,
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
  }),
);

const Description = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 18,
      md: 20,
    },
  }),
);

const Video = styled("video")(({ theme }) =>
  theme.unstable_sx({
    width: "100%",
    borderWidth: 1,
    borderColor: "#dcdcdc",
    borderStyle: "solid",
    borderRadius: 4,
  }),
);

export default LandingIntroVideo;
