import { generateClient } from "aws-amplify/api";
import { useMutation, useQuery } from "react-query";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { User } from "../API";
import * as gqlMutations from "../graphql/mutations"; // create, update, delete
import * as gqlQueries from "../graphql/queries"; // create, update, delete
import { useState } from "react";
import { createLoggerEffect } from "./util";
import { IsDevelop } from "../lib/env";

const { persistAtom } = recoilPersist();

type UserState = User | null;
const client = generateClient({});

const getUser = async (id: string) => {
  const result: any = await client.graphql({
    query: gqlQueries.getUser,
    variables: {
      id: id,
    },
  });
  const user: UserState = result.data.getUser || null;
  return user;
};

const updateUser = async (user: User) => {
  const result: any = await client.graphql({
    query: gqlMutations.updateUser,
    variables: {
      input: {
        id: user.id,
        email: user.email,
      },
    },
  });
  const userState: UserState = result.data.createUser || null;
  return userState;
};

const isChangeState = (prevState: any, currentState: any) => {
  return !Object.is(prevState, currentState);
};

interface RecoilOptions {
  initialRefetch?: boolean; // 初回にAPIから値を取得するかどうか
}

interface RecoilUserProps {
  username: string;
  options?: RecoilOptions;
}

export const useRecoilUser = ({
  username,
  options = {
    initialRefetch: true, // デフォルトは初回にAPIから値を取得する
  },
}: RecoilUserProps) => {
  const [_user, _setUser] = useRecoilState(userState);
  const [isRefetch, setIsRefetch] = useState(options.initialRefetch);
  const refetch = () => setIsRefetch(true);
  // 初回取得時にuserが存在しなければAPIから値を取得する
  const { status: getStatus, data } = useQuery(
    ["user", _user, username, isRefetch],
    async () => {
      if (_user && !isRefetch) return _user;
      // userが存在しないもしくはisRefetchがtrueの場合はAPIから取得する
      setIsRefetch(false);
      const user = await getUser(username);
      _setUser(user);
      return user;
    },
  );
  const user = data || null;
  // userが更新されたらDBに保存する
  const { mutate: setUser, status: setStatus } = useMutation(
    async (user: UserState) => {
      if (user && isChangeState(_user, user)) {
        // userがnullでなく、値が変更されていればAPIに保存する
        await updateUser(user);
        _setUser(user);
      } else {
        _setUser(user);
      }
    },
  );

  return { getStatus, user, setStatus, setUser, refetch };
};

const createEffects = () => {
  if (IsDevelop) {
    return [persistAtom, createLoggerEffect<UserState>("UserState")];
  } else {
    return [persistAtom];
  }
};

export const userState = atom<UserState>({
  key: "userStateAtom",
  default: null,
  effects: createEffects(),
});
