import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Price from "../components/Pricing/Price";

export default function Pricing() {
  const navigate = useNavigate();
  const moveLogin = () => {
    navigate(`/sign?state=signUp`);
  };

  return (
    <Container>
      <Title>{"ご利用料金"}</Title>
      <PanelsContainer>
        <Panel sx={{ borderColor: "darkorange" }}>
          <PanelTitle sx={{ color: "darkorange" }}>
            {"無料トライアル"}
          </PanelTitle>
          <PanelSubTitle sx={{ marginTop: 2, color: "darkorange" }}>
            {"プラン申請日から14日後まで無料"}
          </PanelSubTitle>
          <Price price="0" description="14日間無料" color="darkorange" />
          <PanelDescription sx={{ marginTop: 2 }}>
            {
              "Metalifyはじっくりお試しいただいた上で、使い続けるかどうかを判断していただきたいので、無料トライアル期間を長めにご用意しています。まずはお気軽にお試しください。"
            }
          </PanelDescription>
        </Panel>
        <ArrowContainer>
          <ArrowIcon />
        </ArrowContainer>
        <Panel>
          <PanelTitle>{"ご利用料金"}</PanelTitle>
          <PanelSubTitle sx={{ marginTop: 2 }}>
            {"無料トライアル終了後翌日から有料"}
          </PanelSubTitle>
          <Price price="980" description="1ヶ月につき" />
          <PanelDescription sx={{ marginTop: 2 }}>
            {"・トライアル期間終了日を基準として毎月基準日に決済されます"}
          </PanelDescription>
          <PanelDescription sx={{ marginTop: 2 }}>
            {
              "・月額費用の解約はいつでもマイページから行っていただけます。次回決済日までに解約をしていただければ、次回以降の請求は発生いたしません。"
            }
          </PanelDescription>
        </Panel>
      </PanelsContainer>
      <RegisterButton
        variant="contained"
        sx={{
          marginTop: {
            xs: 4,
            sm: 8,
          },
        }}
        onClick={moveLogin}
      >
        {"まずは無料トライアルを試してみる"}
      </RegisterButton>
    </Container>
  );
}

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    padding: {
      xs: 4,
      sm: 4,
      md: 8,
    },
    textAlign: "center",
    fontSize: 36,
    fontWeight: "bold",
    color: "#393f4c",
  }),
);

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "column",
    },
    padding: {
      xs: 2,
      sm: 4,
      md: 8,
    },
    paddingLeft: {
      xs: 2,
      sm: 12,
      md: 8,
    },
    paddingRight: {
      xs: 2,
      sm: 12,
      md: 8,
    },
    marginBottom: 8,
    minHeight: "100vh",
  }),
);

const ArrowContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    height: "100%",
  }),
);

const ArrowIcon = styled(TrendingFlatIcon)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 120,
    color: "#d3d3d3",
    transform: {
      xs: "rotate(90deg)",
      sm: "rotate(90deg)",
      md: "rotate(0deg)",
    },
  }),
);

const PanelsContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
    },
    justifyContent: "center",
    alignItems: "center",
  }),
);

const Panel = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "solid",
    borderColor: "#393f4c",
    padding: 2,
    flex: 1,
    minHeight: {
      md: 450,
      lg: 400,
      xl: 400,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
);

const PanelTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 28,
    fontWeight: "bold",
    color: "#393f4c",
    textAlign: "center",
  }),
);

const PanelSubTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 18,
    fontWeight: "bold",
    color: "#393f4c",
    textAlign: "center",
  }),
);

const PanelDescription = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 14,
  }),
);

const RegisterButton = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    fontSize: {
      xs: 20,
      sm: 24,
    },
    fontWeight: "bold",
    backgroundColor: "darkorange",
  }),
);
