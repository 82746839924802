import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export interface Props {}

const LandingConcern: React.FC<Props> = () => {
  return (
    <Container>
      <Title>{"こんなお悩みありませんか？"}</Title>
      <ConcernsContainer>
        <Box>
          <ConcernText1>自分の手法に</ConcernText1>
          <ConcernText2>「優位性があるのかわからない」</ConcernText2>
        </Box>
        <Box>
          <ConcernText1>手法はわかっているのに</ConcernText1>
          <ConcernText2>「なぜかトータルで負けている」</ConcernText2>
        </Box>
        <Box>
          <ConcernText1>負けが続くと</ConcernText1>
          <ConcernText2>「適当なトレードをしてしまう」</ConcernText2>
        </Box>
        <Box>
          <ConcernText1>これまでにFXで</ConcernText1>
          <ConcernText2>「何回か挫折している」</ConcernText2>
        </Box>
      </ConcernsContainer>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    paddingRight: {
      xs: 2,
      sm: 4,
      md: 8,
      lg: 16,
      xl: 32,
    },
    gap: {
      xs: 2,
      sm: 2,
      md: 4,
      lg: 4,
    },
  }),
);

const Title = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 24,
      sm: 32,
      md: 40,
    },
    fontWeight: "bold",
  }),
);

const ConcernsContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: {
      xs: "url(/img/landing_concern2.png)",
      sm: "url(/img/landing_concern_pc.png)",
      md: "url(/img/landing_concern_pc.png)",
      lg: "url(/img/landing_concern_pc.png)",
    },
    backgroundSize: "contain",
    borderRadius: 2,
    width: "100%",
    marginTop: {
      xs: 0,
      sm: 4,
      md: 4,
      lg: 0,
    },
    padding: {
      xs: 2,
      sm: 8,
      md: 8,
      lg: 8,
    },
    paddingTop: {
      xs: 4,
      sm: 8,
    },
    paddingBottom: {
      xs: 12,
      sm: 8,
    },
    gap: {
      xs: 3,
      sm: 2,
      md: 4,
      lg: 4,
    },
  }),
);

const ConcernText1 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#393f4c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 16,
      sm: 20,
      md: 24,
    },
    fontWeight: "bold",
  }),
);

const ConcernText2 = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    textAlign: "center",
    color: "#dc143c",
    whiteSpace: "pre-wrap",
    fontSize: {
      xs: 20,
      sm: 24,
      md: 28,
    },
    fontWeight: "bold",
  }),
);

export default LandingConcern;
