import { css } from "styled-components";

export const row = css`
  display: flex;
  flex-direction: row;
`;

export const column = css`
  display: flex;
  flex-direction: column;
`;

export const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const centerVertical = css`
  display: flex;
  align-items: center;
`;

export const centerHorizontal = css`
  display: flex;
  justify-content: center;
`;

export const border = css`
  border: 1px solid black;
  border-radius: 4px;
`;
