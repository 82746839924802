/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    customerId
    backtests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      email
      customerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getBacktest = /* GraphQL */ `query GetBacktest($id: ID!) {
  getBacktest(id: $id) {
    id
    status
    account {
      symbol
      balance
      start
      end
      __typename
    }
    orders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    userBacktestsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBacktestQueryVariables,
  APITypes.GetBacktestQuery
>;
export const listBacktests = /* GraphQL */ `query ListBacktests(
  $id: ID
  $filter: ModelBacktestFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBacktests(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      status
      createdAt
      updatedAt
      userBacktestsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBacktestsQueryVariables,
  APITypes.ListBacktestsQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    ticket
    type
    symbol
    lots
    openTime
    openPrice
    closeTime
    closePrice
    stopLoss
    takeProfit
    swap
    commission
    profit
    expiration
    comment
    magicNumber
    createdAt
    updatedAt
    backtestOrdersId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $id: ID
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrders(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      ticket
      type
      symbol
      lots
      openTime
      openPrice
      closeTime
      closePrice
      stopLoss
      takeProfit
      swap
      commission
      profit
      expiration
      comment
      magicNumber
      createdAt
      updatedAt
      backtestOrdersId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const getUserMigrationTemp =
  /* GraphQL */ `query GetUserMigrationTemp($email: ID!) {
  getUserMigrationTemp(email: $email) {
    email
    customerId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserMigrationTempQueryVariables,
    APITypes.GetUserMigrationTempQuery
  >;
export const listUserMigrationTemps =
  /* GraphQL */ `query ListUserMigrationTemps(
  $email: ID
  $filter: ModelUserMigrationTempFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserMigrationTemps(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      customerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserMigrationTempsQueryVariables,
    APITypes.ListUserMigrationTempsQuery
  >;
