import { Alert, AlertProps, Snackbar, SnackbarProps } from "@mui/material";
import { FC } from "react";
import { atom, useRecoilValue } from "recoil";

export const SnackbarAlert: FC = () => {
  const state = useRecoilValue(snackbarState);

  if (state === null) {
    return null;
  }
  return (
    <Snackbar {...state}>
      <Alert {...state}>{state?.message}</Alert>
    </Snackbar>
  );
};

type SnackbarState = (SnackbarProps & AlertProps) | null;

export const snackbarState = atom<SnackbarState>({
  key: "snackbarStateAtom",
  default: null,
});
